import { useEffect, useState } from 'react'
import Web3 from 'web3'
import axios, { AxiosError } from 'axios'
import { Container, Row } from 'react-bootstrap'
import SectionTitle from '../../components/SectionTitle'
import Main from '../../components/Main'
import Button from '../../components/Button'
import ConnectModal from '../../components/AccountButton/ConnectModal'
import config from '../../config/config.json'
import { useActiveWeb3React } from '../../hooks'
import DashboardTitle from '../../assets/images/dashboard.png'
import { AccountCol, GuildMasterWrapper, GuildMasterTitle, TextContent, GuildMasterButtons, RowContent } from './Styled'
import { toast, Zoom } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage'

function Dashboard(): JSX.Element {
  const [showConnectModal, setShowConnectModal] = useState(false)
  const [isAccountMapped, setAccountMapped] = useState(false)
  const [isRegister, setRegister] = useState(false)
  const [userName, setUserName] = useState('Happy Farmer')
  const [guildRank, setGuildRank] = useState('12')
  const { account, chainId, library, deactivate } = useActiveWeb3React()
  const web3 = new Web3(library)
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]

  const checkMapping = async () => {
    try {
      if (account) {
        const response = await axios.get(`${apiURL}/users/playerInfo/${account}`)

        if (response.status === 200 && response.data) {
          const { isMaster } = response.data
          setAccountMapped(isMaster)
        }
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    checkMapping()
  }, [account, networkId])

  const logout = () => {
    deactivate()
  }

  const onRegister = async () => {
    try {
      setRegister(true)
      if (account) {
        const expiry = 1674208024
        const hashedMsg = web3.utils.sha3(`${expiry}-${account.toLowerCase()}`)
        if (hashedMsg) {
          const signature = await web3.eth.personal.sign(hashedMsg, account.toLowerCase(), '')
          const response = await axios.post(`${apiURL}/masters/createMasterAccount`, {
            expiry,
            master: account,
            signature,
          })
          if (response.status === 200) {
            toast.success(<ToastMessage color="success" bodyText="Success" />, {
              toastId: 'onCreateMasterAccount',
              position: 'bottom-right',
              autoClose: 50000,
              hideProgressBar: true,
              transition: Zoom,
            })
          }
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const msg = _e.response?.data.errors
        toast.error(
          <ToastMessage color="error" bodyText={msg ? msg?.toUpperCase() : 'Could not create master account.'} />,
          {
            toastId: 'onCreateMasterAccount',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          },
        )
      } else {
        console.error(error)
      }
    } finally {
      setRegister(false)
    }
  }

  return (
    <Main>
      <Container>
        {account ? (
          <>
            <Row className="justify-content-center">
              <SectionTitle subTitle="Dashboard" bgImage={DashboardTitle} />
              {!isAccountMapped ? (
                <>
                  <p className="text-center mb-5">
                    Require minimum {config.masterLandNumber[networkId]} lands to create master account
                  </p>
                  <div className="button-wrapper text-center">
                    <Button color="secondary" loading={isRegister} disabled={isRegister} onClick={onRegister}>
                      Register
                    </Button>
                    <Button color="secondary" onClick={logout}>
                      Logout
                    </Button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </Row>
            <GuildMasterWrapper>
              <RowContent className="d-flex justify-content-between">
                <GuildMasterTitle>Guild name</GuildMasterTitle>
                <TextContent>{userName && userName}</TextContent>
              </RowContent>
              <RowContent className="d-flex justify-content-between">
                <GuildMasterTitle>Wallet Address</GuildMasterTitle>
                <TextContent>
                  {account && `${account.substring(0, 6)}...${account.substring(account.length - 4)}`}
                </TextContent>
              </RowContent>
              <RowContent className="d-flex justify-content-between">
                <GuildMasterTitle>Guild rank</GuildMasterTitle>
                <TextContent># {guildRank}</TextContent>
              </RowContent>
              <RowContent>
                <GuildMasterTitle>Participate in Guild Battle</GuildMasterTitle>
                <GuildMasterButtons>
                  <button>Yes</button>
                  <button>No</button>
                </GuildMasterButtons>
              </RowContent>
            </GuildMasterWrapper>
          </>
        ) : (
          <Row className="justify-content-center">
            <SectionTitle subTitle="Dashboard" bgImage={DashboardTitle}>
              Create Master Account
            </SectionTitle>
            <AccountCol xs={11} md={8} className="text-center">
              <h5 className="mb-3">Looks like your wallet is not connected.</h5>
              <p className="mb-5">Connect your wallet to continue</p>
              <Button onClick={() => setShowConnectModal(true)}>Connect Wallet</Button>
              <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
            </AccountCol>
          </Row>
        )}
      </Container>
    </Main>
  )
}

export default Dashboard
