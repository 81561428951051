import { useEffect, useState, useRef } from 'react'
import { Form } from 'react-bootstrap'
import axios, { AxiosError } from 'axios'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../../components/ToastMessage'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import NFT from '../../../types/NFT'
import NFTListItem from './NFTListItem'
import { NFTList, DepositAllWrapper, EmptyText } from '../Styled'
import { useActiveWeb3React, useGameControlContract, useNFTsInGame } from '../../../hooks'
import config from '../../../config/config.json'

interface IDepositNFTsModalProps {
  show: boolean
  onHide: () => void
  onRefresh: () => void
  type: 'land' | 'item'
}

function DepositNFTsModal(props: IDepositNFTsModalProps): JSX.Element {
  const { show, onHide, onRefresh, type } = props
  const componentMounted = useRef(true)

  const [isSelectAll, setSelectAll] = useState(false)
  const [tokenIds, setTokenIds] = useState<number[]>([])
  const [isDepositing, setDepositing] = useState(false)
  const [isValid, setValid] = useState(false)

  const [nfts, setNFTs] = useState<NFT[]>([])
  const [filteredNFTs, setFilteredNFTs] = useState<NFT[]>([])

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const gameContract = useGameControlContract(config.contracts[networkId].GameControl)
  const ntfsIngameCallback = useNFTsInGame(account, 'outgame')

  const MAX_ITEMS = 10

  const fetchNFTs = async () => {
    try {
      const response = await ntfsIngameCallback()
      const { lands: _lands, items: _items } = response
      setNFTs(type === 'land' ? _lands : _items)
      setFilteredNFTs(type === 'land' ? _lands : _items)
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchNFTs()

    if (!show) {
      setTokenIds([])
      setValid(false)
      setSelectAll(false)
    }

    return () => {
      componentMounted.current = false
    }
  }, [show])

  const onSelectAll = () => {
    const _tokenIds = nfts.map(nft => nft.tokenId)
    if (isSelectAll) {
      setTokenIds([])
    } else {
      setTokenIds(_tokenIds)
    }
    setSelectAll(!isSelectAll)
    setValid(_tokenIds.length > 0 && _tokenIds.length <= MAX_ITEMS)
  }

  const onItemSelected = (tokenId: number, selected: boolean) => {
    let _tokenIds = [...tokenIds]
    if (selected) {
      _tokenIds.push(tokenId)
    } else {
      _tokenIds = tokenIds.filter(id => id !== tokenId)
    }
    setTokenIds(_tokenIds)
    setValid(_tokenIds.length > 0 && _tokenIds.length <= MAX_ITEMS)
  }

  const onDepositAllNFTs = async () => {
    try {
      setDepositing(true)

      if (tokenIds.length > MAX_ITEMS) {
        const error = new Error('MaximumItems')
        error.message = 'Please select a maximum of 5 items'
        throw error
      }

      if (account && gameContract) {
        const receipt = await gameContract.methods
          .depositNFTsToPlay(config.contracts[networkId].LandNFT, tokenIds)
          .send({ from: account })

        if (receipt) {
          await onRefresh()
          onHide()

          toast.success(
            <ToastMessage
              color="success"
              bodyText="Let's play!!!"
              link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              linkText="View Transaction"
            />,
            {
              toastId: 'onDepositAllNFTs',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            },
          )
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const message = _e.response?.data.errors
        toast.error(
          <ToastMessage color="error" bodyText={message ? message.toUpperCase() : 'Could not synchronized NFTs.'} />,
          {
            toastId: 'onDepositAllNFTs',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          },
        )
      } else {
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          toast.error(<ToastMessage color="error" bodyText="Could not synchronized. Please try again." />, {
            toastId: 'onDepositAllNFTs',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
      console.error(error)
    } finally {
      setDepositing(false)
    }
  }

  return (
    <Modal size="lg" show={show} onHide={onHide} title={`Synchronize ${type === 'land' ? 'lands' : 'items'} to play`}>
      {nfts.length > 0 ? (
        <>
          <NFTList>
            {filteredNFTs.map(nft => {
              return (
                <NFTListItem
                  key={nft.tokenId}
                  nft={nft}
                  selectAll={isSelectAll}
                  onRefresh={onRefresh}
                  onItemSelectedCallback={onItemSelected}
                />
              )
            })}
          </NFTList>
          <DepositAllWrapper>
            <Form.Check
              checked={isSelectAll}
              type="checkbox"
              id="select-all-nfts"
              label="Select All"
              onChange={onSelectAll}
            />
            {type === 'land' ? (
              <Button
                loading={isDepositing}
                disabled={isDepositing || !isValid}
                onClick={onDepositAllNFTs}
              >{`Synchronize ${tokenIds.length ? tokenIds.length : ''} ${
                tokenIds.length > 1 ? 'lands' : 'land'
              }`}</Button>
            ) : (
              <Button
                loading={isDepositing}
                disabled={isDepositing || !isValid}
                onClick={onDepositAllNFTs}
              >{`Synchronize ${tokenIds.length ? tokenIds.length : ''} ${
                tokenIds.length > 1 ? 'items' : 'item'
              }`}</Button>
            )}
          </DepositAllWrapper>
          <Form.Text className="d-block mt-3">{`You can synchronized up to ${MAX_ITEMS} ${
            type === 'land' ? 'lands' : 'items'
          } at the same time`}</Form.Text>
        </>
      ) : (
        <EmptyText>{`No ${type === 'land' ? 'lands' : 'items'} were found to be synchronized.`}</EmptyText>
      )}
    </Modal>
  )
}

export default DepositNFTsModal
