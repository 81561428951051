import styled from 'styled-components/macro'
import { Row, Col, Form, FormControl, InputGroup, FloatingLabel } from 'react-bootstrap'
import DetailBg from '../../assets/images/single-account/detail-bg.png'
import InfoBg from '../../assets/images/single-account/info-bg.png'
import DownItem from '../../assets/images/guild-management/item.png'

export const HeaderWrapper = styled.div`
  display: flex;
  position: relative;
  padding-left: 10rem;
  margin-bottom: -45px;
`

export const GoBackBtn = styled.div`
  margin-top: 15px;
  margin-right: 30px;
  cursor: pointer;
`

export const GuildTitles = styled.h2`
  font-size: 100px;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
  color: #731c00;
`

export const DetailWrapper = styled.div`
  background-image: url(${DetailBg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  padding: 6rem 6rem 0;
  z-index: 1;
`

export const LandsWrapper = styled.div``

export const LandsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const RightWrapper = styled.div`
  width: fit-content;
`

export const RowTokens = styled(Row)`
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`

export const DottedLine = styled.div`
  border: 1px dashed #8e4400;
  margin: 0 15px;
`

export const UpdateInfoWrapper = styled(Col)`
  background-image: url(${InfoBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export const EmptyText = styled.p`
  padding: 6rem 0;
  text-align: center;
  color: #5a2b00;
`
export const ButtonsRow = styled(Row)`
  justify-content: center;
  text-align: center;
  margin-bottom: 2rem;

  button {
    margin: 0.5rem;
  }
`
// Tokens
export const TokensWrapper = styled(Col)`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 43.39%, rgba(0, 0, 0, 0) 100%), #790017;
  border-radius: 20px;
  padding: 20px;
  width: fit-content;

  hr {
    background-color: #8e4400;
    height: 2px;
  }

  p {
    margin-bottom: 0;
  }
`

export const ValueWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const TokensTitle = styled.p`
  font-weight: 400;
  font-size: 26px;
  line-height: 130%;
`

export const TokensText = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  margin-right: 5rem;
`

export const TokensValue = styled.p`
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: #ff931f;
`

export const FormLabel = styled(FloatingLabel)`
  &::after {
    content: '';
    position: absolute;
    border: 1px dashed #5a2b00;
    bottom: 0;
    width: 100%;
    height: 1px;
    opacity: 0.3;
  }

  label {
    color: #5a2b00;
    padding-left: 0;
  }
`

export const SecondFormLabel = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  text-align: center;
  height: 58px;
  margin: 0;
  padding-top: 20px;

  &::after {
    content: '';
    position: absolute;
    border: 1px dashed #5a2b00;
    bottom: 0;
    width: 100%;
    height: 1px;
    opacity: 0.3;
  }
`

export const LabelTitle = styled.p`
  color: #5a2b00;
  font-size: 16px;
  margin: 0;
`

export const FormInput = styled(Form.Control)`
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  position: relative;
  color: #731c00 !important;
  padding-top: 20px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;

  &:placeholder-shown + small {
    display: none !important;
  }

  &[readonly] {
    opacity: 0.7;
    color: #ffffff22;
    background-color: #ffffff22 !important;

    &:focus,
    &:active {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
`

export const FormSelect = styled(Form.Select)`
  background-image: url(${DownItem});
  background-color: transparent !important;
  background-size: 17px 16px;
  box-shadow: none !important;
  text-align: center;
  font-size: 20px;
  color: #731c00;
  border: none;

  &:focus,
  &:active {
    border-color: transparent;
  }

  option {
    text-align: center;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  padding-bottom: 6rem;
`
export const TokenName = styled.p`
  font-size: 18px;
  color: #5a2b00;
`
export const BalanceText = styled.p`
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  color: #ff931d;
`
export const Input = styled(FormControl)`
  box-shadow: none !important;
  border-radius: 0;
  border: ${props => props.borderwidth || '2px'} solid rgba(255, 255, 255, 0.3);
  background-color: transparent !important;
  color: #731c00 !important;
  font-size: 20px;
  padding: ${props => props.paddinginput || '.375rem .75rem'};

  &:focus,
  &:active {
    border-color: #fff;
  }

  &:placeholder-shown + p {
    display: none;
  }
`

// NFTs item in modal
export const NFTList = styled.div`
  height: 350px;
  overflow: auto;
`
export const NFTItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 5px;
  border-bottom: 1px dashed #5a2b00;

  > div {
    flex: 1;
  }

  &.is-selected {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .form-check {
    display: flex;
    align-self: center;
    padding: 0;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    flex: 0 0 20px;

    .form-check-input {
      float: none;
      margin: 0 0.5rem 0 0;
      cursor: pointer;

      &:checked {
        background-color: ${props => props.theme.color.orange[400]};
        border-color: ${props => props.theme.color.orange[400]};
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem ${props => props.theme.color.orange[400]}40;
      }
    }

    .form-check-label {
      cursor: pointer;
    }
  }
`
export const SmallNFTID = styled.span`
  margin-right: 0.5rem;
  color: #5a2b00;
`
export const DepositAllWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;

  .form-check {
    display: flex;
    align-items: center;
    padding: 0;
    cursor: pointer;
    color: #fff;

    .form-check-input {
      float: none;
      margin: 0 0.5rem 0 0;
      cursor: pointer;

      &:checked {
        background-color: ${props => props.theme.color.orange[400]};
        border-color: ${props => props.theme.color.orange[400]};
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem ${props => props.theme.color.orange[400]}40;
      }
    }

    .form-check-label {
      cursor: pointer;
    }
  }
`

// Deposit Modal
export const DepositWrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  row-gap: 20px;
`
export const DepositInputGroup = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: 10px;
  background: transparent;
  z-index: 1;
  margin-bottom: 20px;

  &::after {
    content: '';
    position: absolute;
    border: 1px dashed #5a2b00;
    bottom: -10px;
    width: 100%;
    height: 1px;
    opacity: 0.3;
  }
`
export const DepositInputInfo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0;
`
export const StyledInputGroup = styled(InputGroup)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
`

export const UpdateFormGroup = styled.div`
  display: flex;
  padding: 2rem 5rem 0;
`

export const InfoGroup = styled(Col)`
  padding: 20px;
`

export const FormTextDanger = styled(Form.Text)`
  font-size: 14px;
`
