import { useEffect, useState, useRef } from 'react'
import axios, { AxiosError } from 'axios'
import Web3 from 'web3'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../../components/ToastMessage'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import NFTListItem from './NFTListItem'
import { NFTList, DepositAllWrapper, EmptyText } from '../Styled'
import { useActiveWeb3React } from '../../../hooks'
import config from '../../../config/config.json'

interface IDepositNFTsModalProps {
  player: string
  tokenAddress: string
  show: boolean
  onHide: () => void
  onRefresh: () => void
  type: 'land' | 'item'
}

function DepositNFTsModal(props: IDepositNFTsModalProps): JSX.Element {
  const { player, tokenAddress, show, onHide, onRefresh, type } = props
  const componentMounted = useRef(true)

  const [nfts, setNFTs] = useState<any>([])
  const [isSelectAll, setSelectAll] = useState(false)
  const [tokenIds, setTokenIds] = useState<number[]>([])
  const [isDepositing, setDepositing] = useState(false)
  const [isValid, setValid] = useState(false)

  const { account, chainId, library } = useActiveWeb3React()
  const web3 = new Web3(library)
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]

  const MAX_ITEMS = 10

  const fetchNFTsUnassign = async () => {
    try {
      const response = await axios.get(`${apiURL}/masters/listUnassign/${account}`)
      if (response.status === 200 && response.data) {
        const tokensUnassign = response.data.tokens
        setNFTs(tokensUnassign)
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchNFTsUnassign()
  }, [account])

  useEffect(() => {
    if (!show) {
      setTokenIds([])
      setValid(false)
      setSelectAll(false)
    }

    return () => {
      componentMounted.current = false
    }
  }, [show])

  const onItemSelected = (tokenId: number, selected: boolean) => {
    let _tokenIds = [...tokenIds]
    if (selected) {
      _tokenIds.push(tokenId)
    } else {
      _tokenIds = tokenIds.filter(id => id !== tokenId)
    }
    setTokenIds(_tokenIds)
    setValid(_tokenIds.length > 0 && _tokenIds.length <= MAX_ITEMS)
  }

  const onDepositAllNFTs = async () => {
    try {
      setDepositing(true)

      if (tokenIds.length > MAX_ITEMS) {
        const error = new Error('MaximumItems')
        error.message = 'Please select a maximum of 5 items'
        throw error
      }

      const expiry = (Date.now() / 1000 + 120).toFixed(0)
      const msg = `${account}-${player}-${tokenAddress}-${tokenIds}-${expiry}`
      const hashedMsg = web3.utils.sha3(msg.toLowerCase())
      const _tokenIds = tokenIds.map(String)
      if (hashedMsg && account) {
        const signature = await web3.eth.personal.sign(hashedMsg, account, '')
        const receipt = await axios.post(`${apiURL}/masters/transferNFTToSubAccount`, {
          master: account,
          player,
          tokenAddress,
          tokenIds: _tokenIds.join(','),
          expiry,
          signature,
        })

        if (receipt) {
          await onRefresh()
          onHide()

          toast.success(<ToastMessage color="success" bodyText="Let's play!!!" linkText="View Transaction" />, {
            toastId: 'onDepositAllNFTs',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const message = _e.response?.data.errors
        toast.error(
          <ToastMessage color="error" bodyText={message ? message.toUpperCase() : 'Could not synchronized NFTs.'} />,
          {
            toastId: 'onDepositAllNFTs',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          },
        )
      } else {
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          toast.error(<ToastMessage color="error" bodyText="Could not synchronized. Please try again." />, {
            toastId: 'onDepositAllNFTs',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
      console.error(error)
    } finally {
      setDepositing(false)
    }
  }

  return (
    <Modal show={show} onHide={onHide} title={`Assign ${type === 'land' ? 'lands' : 'items'} to Scholar`}>
      {nfts.length > 0 ? (
        <>
          <NFTList>
            {nfts.map(nft => {
              return (
                <NFTListItem
                  key={nft.tokenId}
                  nft={nft}
                  selectAll={isSelectAll}
                  onRefresh={onRefresh}
                  onItemSelectedCallback={onItemSelected}
                />
              )
            })}
          </NFTList>
          <DepositAllWrapper>
            {type === 'land' ? (
              <Button loading={isDepositing} disabled={isDepositing || !isValid} onClick={onDepositAllNFTs}>{`Assign ${
                tokenIds.length ? tokenIds.length : ''
              } ${tokenIds.length > 1 ? 'lands' : 'land'}`}</Button>
            ) : (
              <Button loading={isDepositing} disabled={isDepositing || !isValid} onClick={onDepositAllNFTs}>{`Assign ${
                tokenIds.length ? tokenIds.length : ''
              } ${tokenIds.length > 1 ? 'items' : 'item'}`}</Button>
            )}
          </DepositAllWrapper>
          <EmptyText style={{ padding: '0', marginTop: '10px' }}>{`You can assign up to ${MAX_ITEMS} ${
            type === 'land' ? 'lands' : 'items'
          } at the same time`}</EmptyText>
        </>
      ) : (
        <EmptyText>{`No ${type === 'land' ? 'lands' : 'items'} were found to be assign.`}</EmptyText>
      )}
    </Modal>
  )
}

export default DepositNFTsModal
