import { Button as BootstrapButton } from 'react-bootstrap'
import styled, { ThemeProvider, keyframes } from 'styled-components/macro'
import { CgSpinner } from 'react-icons/cg'
import Item from '../../assets/images/guild-management/item.png'

const PrimaryTheme = {
  background:
    'radial-gradient(100% 274.18% at 97.31% 0%, rgba(255, 230, 0, 0.4) 0%, rgba(255, 230, 0, 0) 100%), linear-gradient(180deg, rgba(255, 229, 63, 0.2) 0%, rgba(255, 229, 63, 0) 100%), #ff931d;',
  border: 'transparent',
  color: '#731c00',
  hover: {
    background:
      'linear-gradient(270deg, rgba(255, 255, 29, 0.3) 0%, rgba(255, 255, 29, 0) 50%), linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), #d34c00;',
    border: 'transparent',
    color: '#fff',
  },
}

const SecondaryTheme = {
  background:
    'radial-gradient(100% 274.18% at 97.31% 0%, rgba(255, 230, 0, 0.4) 0%, rgba(255, 230, 0, 0) 100%), linear-gradient(180deg, rgba(255, 63, 63, 0.2) 0%, rgba(255, 63, 63, 0) 100%), #d92d2d;',
  border: 'transparent',
  color: '#fff',
  hover: {
    background:
      'linear-gradient(270deg, rgba(255, 255, 29, 0.3) 0%, rgba(255, 255, 29, 0) 50%), linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), #c50000;',
    border: 'transparent',
    color: '#ffc11b',
  },
}

const ThirdTheme = {
  background: 'transparent',
  border: 'rgba(90, 43, 0, 0.2)',
  color: '#5a2b00',
  hover: {
    background: 'transparent',
    border: 'rgba(90, 43, 0, 0.2)',
    color: '#731c00',
  },
}

const TextSize = {
  small: '16px',
  medium: '18px',
  large: '20px',
  bigger: '24px',
}

const BtnPaddingSize = {
  small: '10px 15px',
  medium: '15px 20px',
  large: '20px',
  bigger: '1.25rem',
}

const StyledButton = styled(BootstrapButton)`
  background: ${props => props.theme.background};
  border: 1px solid ${props => props.theme.border};
  position: relative;
  padding: ${props => props.padding};
  display: inline-block;
  text-align: center;
  line-height: 1rem;
  border-radius: 14px !important;
  font-size: ${props => props.fontSize};
  font-weight: 400;
  color: ${props => props.theme.color};
  margin: 0;

  &:hover,
  &:focus,
  &:active {
    background: ${props => props.theme.hover.background};
    border-color: ${props => props.theme.hover.border};
    color: ${props => props.theme.hover.color};
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 0.25rem ${props => props.theme.hover.background}42;
  }

  &:disabled {
    background: ${props => props.theme.background};
    border-color: ${props => props.theme.border};
    color: ${props => props.theme.color};
  }

  svg {
    margin-right: 0.5rem;
  }

  img {
    width: ${props => (props.fontSize === '20px' || props.fontSize === '24px' ? '21px' : 'auto')};
    height: ${props => (props.fontSize === '20px' || props.fontSize === '24px' ? '20px' : 'auto')};
    margin-left: 15px;
  }
`
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const LoadingIcon = styled(CgSpinner)`
  animation: ${spin} 0.75s linear infinite;
`

interface IButtonProps {
  color?: 'primary' | 'secondary' | 'third'
  icon?: boolean
  children?: any
  disabled?: boolean
  loading?: boolean
  loadingText?: string
  fontSize?: 'small' | 'medium' | 'large' | 'bigger'
  url?: string
  onClick?: (e?: any) => void
}

function Button(props: IButtonProps): JSX.Element {
  const { color, icon, children, disabled, loading, loadingText, fontSize, url, onClick } = props

  let _theme = PrimaryTheme

  switch (color) {
    case 'primary':
      _theme = PrimaryTheme
      break
    case 'secondary':
      _theme = SecondaryTheme
      break
    case 'third':
      _theme = ThirdTheme
      break
  }

  let _size = ''
  let _padding = ''

  switch (fontSize) {
    case 'small':
      _size = TextSize.small
      _padding = BtnPaddingSize.small
      break
    case 'medium':
      _size = TextSize.medium
      _padding = BtnPaddingSize.medium
      break
    case 'large':
      _size = TextSize.large
      _padding = BtnPaddingSize.large
      break
    case 'bigger':
      _size = TextSize.bigger
      _padding = BtnPaddingSize.bigger
      break
  }

  return (
    <ThemeProvider theme={_theme}>
      <StyledButton disabled={disabled} onClick={onClick} href={url} fontSize={_size} padding={_padding} icon={icon}>
        {loading && <LoadingIcon size={18} />}
        {loading && loadingText ? loadingText : <>{children}</>}
        {icon && <img src={Item} />}
      </StyledButton>
    </ThemeProvider>
  )
}

Button.defaultProps = {
  color: 'primary',
  fontSize: 'bigger',
}

export default Button
