import { useEffect } from 'react'
import styled from 'styled-components/macro'
import PreloadGif from '../../assets/images/logo.png'

const PreLoaderWrapper = styled.div`
  background-color: ${props => props.theme.color.black};
  width: 100%;
  height: 100%;
  position: fixed;
  margin-top: 0;
  top: 0;
  z-index: 99;
`
const LoadingCenter = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
const LoadingCenterAbsolute = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const PreloadImage = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 70%;
`

function PreLoader(): JSX.Element {
  useEffect(() => {
    const preloader = document.getElementById('preloader')

    if (preloader) {
      setTimeout(() => {
        preloader.style.transition = '.5s'
        preloader.style.opacity = '0'
        preloader.style.visibility = 'hidden'
      }, 500)

      setTimeout(() => {
        preloader.remove()
      }, 1000)
    }
  })

  return (
    <PreLoaderWrapper id="preloader">
      <LoadingCenter>
        <LoadingCenterAbsolute>
          <PreloadImage src={PreloadGif} alt="Preloader" />
        </LoadingCenterAbsolute>
      </LoadingCenter>
    </PreLoaderWrapper>
  )
}

export default PreLoader
