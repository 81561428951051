import styled from 'styled-components/macro'
import { Table, Col, Form, FloatingLabel } from 'react-bootstrap'
import WalletBg from '../../assets/images/wallet.svg'
import DownItem from '../../assets/images/guild-management/item.png'
import SelectBox from '../../components/SelectBox'

export const AccountCol = styled(Col)`
  background: #ffe5cd;
  border-radius: 14px;
  padding: 20px 20px 40px 20px;
  margin-top: -30px;
  width: 75%;

  h5 {
    margin-bottom: 1.5rem;
    @media (min-width: 992px) {
      margin-bottom: 3rem;
    }
  }
`

export const HeaderWrapper = styled.div`
  padding-left: 10rem;
`
export const GuildTitles = styled.h2`
  font-size: 100px;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
  color: #731c00;
`

export const BalanceWrapper = styled.div`
  margin: 1rem 0;
`
export const GameAccountWrapper = styled.div`
  display: block;
`

export const PercentText = styled.p`
  font-size: 20px;
  color: #731c00;
  font-weight: 500;
  margin: 0;
  padding-top: 25px;
  padding-right: 10px;
`

export const FormSelect = styled(Form.Select)`
  background-image: url(${DownItem});
  background-color: transparent !important;
  background-size: 17px 16px;
  box-shadow: none !important;
  text-align: center;
  font-size: 20px;
  color: #731c00;
  border: none;
  width: auto;

  &:focus,
  &:active {
    border-color: transparent;
  }

  option {
    text-align: center;
  }
`

export const FormLabel = styled(FloatingLabel)`
  &::after {
    content: '';
    position: absolute;
    border: 1px dashed #5a2b00;
    bottom: 0;
    width: 100%;
    height: 1px;
    opacity: 0.3;
  }

  label {
    color: #5a2b00;
    padding-left: 0;
  }
`

export const SecondFormLabel = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  text-align: center;
  height: 58px;
  margin: 0;
  padding-top: 20px;

  &::after {
    content: '';
    position: absolute;
    border: 1px dashed #5a2b00;
    bottom: 0;
    width: 100%;
    height: 1px;
    opacity: 0.3;
  }
`

export const LabelTitle = styled.p`
  color: #5a2b00;
  font-size: 16px;
  margin: 0;
`

export const FormInput = styled(Form.Control)`
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  position: relative;
  color: #731c00 !important;
  padding-top: 20px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;

  &:placeholder-shown + small {
    display: none !important;
  }

  &[readonly] {
    opacity: 0.7;
    color: #ffffff22;
    background-color: #ffffff22 !important;

    &:focus,
    &:active {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
`

export const FormTextDanger = styled(Form.Text)`
  font-size: 14px;
`

export const StyledButton = styled.button`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${WalletBg}) no-repeat center center;
  background-size: 100%;
  width: 140px;
  height: 42px;
  color: #fff;
  font-family: 'Lilita One', cursive;
  font-size: 12px;
  line-height: 1;
  border: none;
  &:hover {
    transform: translateY(-5px);
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;

  button {
    margin-right: 0.5rem;
  }
`
export const ReferralLink = styled.span`
  font-size: 12px;
  display: block;
  color: ${props => props.theme.color.orange[300]};
  margin-bottom: 1rem;
  cursor: pointer;
`

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const StyledTable = styled(Table)`
  text-align: center;
  font-size: 16px;
  margin: 0;

  th {
    background: #5a2b00;
    border: 1px dashed #ff931d;
    border-top: none;
    padding: 40px 20px;
  }

  td {
    color: #5a2b00;
    border: 1px dashed #5a2b00;
  }

  a {
    color: #731c00;
  }
`

export const BorderTopTable = styled.div`
  background: #5a2b00;
  height: 10px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
`

export const BorderBottomTable = styled.div`
  background: #5a2b00;
  height: 10px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
`

export const HeaderTitle = styled.p`
  color: #ff931d;
  margin-bottom: 0;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button:nth-child(2) {
    margin: 0 10px;
  }
`

export const CountDownWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #5a2b00;
  margin-top: 20px;
`

export const CountDownText = styled.div`
  background-color: #731c00;
  border-radius: 14px;
  width: fit-content;
  padding: 8px 15px;
  color: #fff;
  margin-left: 10px;
`

export const ActionButton = styled.button`
  color: #ff931d;
  background: #5a2b00;
  border: none;
  border-radius: 4px;
  margin: 0 2px;
`

export const IndexText = styled.span`
  color: #731c00;
  margin: 0 10px;
`

export const StyledSelect = styled(SelectBox)`
  margin-left: 10px;
`

export const TokenWithdraw = styled.span`
  color: #5a2b00;
  margin-right: 20px;
`
