import { useEffect, useState, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
// import Web3 from 'web3'
import axios from 'axios'
import moment from 'moment'
import { Container, Tab } from 'react-bootstrap'
import Main from '../../components/Main'
import { useActiveWeb3React } from '../../hooks'
import HistoryTable from './HistoryTable'
import config from '../../config/config.json'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import BigNumber from 'bignumber.js'
import {
  HeaderWrapper,
  GoBackBtn,
  HistoryTitles,
  BodyWrapper,
  StyledTabs,
  TableWrapper,
  DottedLine,
  BorderTopTable,
  BorderBottomTable,
} from './Styled'
import GuildManagementBg from '../../assets/images/guild-management-bg.png'
import Vector from '../../assets/images/history/vector.svg'
import { fromWei } from '../../utils'

BigNumber.config({ EXPONENTIAL_AT: [-100, 100] })
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

function History(): JSX.Element {
  const [topupHistory, setTopupHistory] = useState<any>([])
  const [claimedHistory, setClaimedHistory] = useState<any>([])
  const [spentHistory, setSpentHistory] = useState<any>([])
  const [username, setUsername] = useState('')

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]
  // @ts-ignore
  const { slug } = useParams()
  const address = slug

  const fetchSubAccountInfo = async () => {
    try {
      if (address) {
        const response = await axios.get(`${apiURL}/masters/subAccount/${address}`)

        if (response.data) {
          const _username = response.data.username
          setUsername(_username)
        }
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const fetchTopupData = async () => {
    try {
      if (account) {
        const _topupHistory = await axios.get(`${apiURL}/masters/history/topup/${account}`)

        if (_topupHistory.data) {
          setTopupHistory(_topupHistory.data)
        }
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const fetchClaimedData = async () => {
    try {
      if (account) {
        const _claimedHistory = await axios.get(`${apiURL}/masters/history/claimed/${account}`)

        if (_claimedHistory.data) {
          setClaimedHistory(_claimedHistory.data)
        }
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const fetchSpentData = async () => {
    try {
      if (account) {
        const _spentHistory = await axios.get(`${apiURL}/masters/history/spent/${account}`)

        if (_spentHistory.data) {
          setSpentHistory(_spentHistory.data)
        }
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchSubAccountInfo()
    fetchTopupData()
    fetchClaimedData()
    fetchSpentData()
  }, [account, networkId])

  const topupColumns = useMemo(
    () => [
      {
        Header: 'Timestamp',
        id: 'timestamp',
        accessor: d => (
          <div>
            <p style={{ marginBottom: '0', marginLeft: '10px', textAlign: 'start' }}>
              {moment.unix(d.createdTime).format('dddd, MMMM d, YYYY')}
            </p>
          </div>
        ),
      },
      {
        Header: 'Amount HPL',
        id: 'amountHPL',
        accessor: d => (
          <div>
            <p style={{ marginBottom: '0' }}>{new BigNumber(d.hplAmount).dividedBy(1e18).toFixed(2)}</p>
          </div>
        ),
      },
      {
        Header: 'Amount HPW',
        id: 'amountHPW',
        accessor: d => (
          <div>
            <p style={{ marginBottom: '0' }}>{new BigNumber(d.hpwAmount).dividedBy(1e18).toFixed(2)}</p>
          </div>
        ),
      },
    ],
    [],
  )

  const claimedColumns = useMemo(
    () => [
      {
        Header: 'Timestamp',
        id: 'timestamp',
        accessor: d => (
          <div>
            <p style={{ marginBottom: '0', marginLeft: '10px', textAlign: 'start' }}>
              {moment.unix(d.claimTime).format('dddd, MMMM d, YYYY')}
            </p>
          </div>
        ),
      },
      {
        Header: 'Amount HPL',
        id: 'amountHPL',
        accessor: d => (
          <div>
            <p style={{ marginBottom: '0' }}>{new BigNumber(d.hplAmount).dividedBy(1e18).toFixed(2)}</p>
          </div>
        ),
      },
      {
        Header: 'Amount HPW',
        id: 'amountHPW',
        accessor: d => (
          <div>
            <p style={{ marginBottom: '0' }}>{new BigNumber(d.hpwAmount).dividedBy(1e18).toFixed(2)}</p>
          </div>
        ),
      },
    ],
    [],
  )

  const spentColumns = useMemo(
    () => [
      {
        Header: 'Timestamp',
        id: 'timestamp',
        accessor: d => (
          <div>
            <p style={{ marginBottom: '0', marginLeft: '10px', textAlign: 'start' }}>
              {moment.unix(d.spentTime).format('dddd, MMMM d, YYYY')}
            </p>
          </div>
        ),
      },
      {
        Header: 'Spent HPL',
        id: 'spentHPL',
        accessor: d => (
          <>
            <p style={{ marginBottom: '0' }}>{new BigNumber(d.hplSpent).dividedBy(1e18).toFixed(2)}</p>
          </>
        ),
      },
      {
        Header: 'Reward HPL',
        id: 'rewardHPL',
        accessor: d => (
          <>
            <p style={{ marginBottom: '0' }}>{new BigNumber(d.hplReward).dividedBy(1e18).toFixed(2)}</p>
          </>
        ),
      },
      {
        Header: 'Spent HPW',
        id: 'spentHPW',
        accessor: d => (
          <>
            <p style={{ marginBottom: '0' }}>{new BigNumber(d.hpwSpent).dividedBy(1e18).toFixed(2)}</p>
          </>
        ),
      },
      {
        Header: 'Reward HPW',
        id: 'rewardHPW',
        accessor: d => (
          <>
            <p style={{ marginBottom: '0' }}>{new BigNumber(d.hpwReward).dividedBy(1e18).toFixed(2)}</p>
          </>
        ),
      },
    ],
    [],
  )

  const ChartLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  const ChartTopupOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Amount HPL and Amount HPW',
      },
    },
  }

  const ChartSpentOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Amount HPL for Guild, Amount HPL for Scholar',
      },
    },
  }

  const ChartTopupData = {
    labels: ChartLabels,
    datasets: [
      {
        label: 'Amount HPL',
        data: topupHistory.map(item => fromWei(item.hplAmount).toNumber()),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'Amount HPW',
        data: topupHistory.map(item => fromWei(item.hpwAmount).toNumber()),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  }

  const ChartClaimedData = {
    ChartLabels,
    datasets: [
      {
        label: 'Amount HPL',
        data: claimedHistory.map(item => fromWei(item.hplAmount).toNumber()),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'Amount HPW',
        data: claimedHistory.map(item => fromWei(item.hpwAmount).toNumber()),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  }

  const ChartSpentData = {
    ChartLabels,
    datasets: [
      {
        label: 'Spent HPL',
        data: spentHistory.map(item => fromWei(item.hplSpent).toNumber()),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'Reward HPL',
        data: spentHistory.map(item => fromWei(item.hplReward).toNumber()),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Spent HPW',
        data: spentHistory.map(item => fromWei(item.hpwSpent).toNumber()),
        borderColor: '#fab405',
        backgroundColor: '#f9e39a',
      },
      {
        label: 'Reward HPW',
        data: spentHistory.map(item => fromWei(item.hpwReward).toNumber()),
        borderColor: '#2e9e49',
        backgroundColor: '#2e9e49a3',
      },
    ],
  }

  return (
    <Main bgImage={GuildManagementBg}>
      <Container fluid>
        <HeaderWrapper>
          <GoBackBtn>
            <Link to={`/manager/${address}`}>
              <img src={Vector} />
            </Link>
          </GoBackBtn>
          <HistoryTitles>{username}'s history</HistoryTitles>
        </HeaderWrapper>
        <BodyWrapper>
          <StyledTabs defaultActiveKey="topup">
            <Tab eventKey="topup" title="Transfer-in History">
              {topupHistory && (
                <TableWrapper>
                  <HistoryTable columns={topupColumns} data={topupHistory} />
                  <DottedLine />
                  <div style={{ padding: '20px', width: '40%' }}>
                    <Line options={ChartTopupOptions} data={ChartTopupData} />
                  </div>
                </TableWrapper>
              )}
            </Tab>
            <Tab eventKey="claimed" title="Claim and Payout History">
              {claimedHistory && (
                <TableWrapper>
                  <HistoryTable columns={claimedColumns} data={claimedHistory} />
                  <DottedLine />
                  <div style={{ padding: '20px', width: '40%' }}>
                    <Line options={ChartTopupOptions} data={ChartClaimedData} />
                  </div>
                </TableWrapper>
              )}
            </Tab>
            <Tab eventKey="spent" title="Spent History">
              {spentHistory && (
                <TableWrapper>
                  <HistoryTable columns={spentColumns} data={spentHistory} />
                  <DottedLine />
                  <div style={{ padding: '20px', width: '40%' }}>
                    <Line options={ChartSpentOptions} data={ChartSpentData} />
                  </div>
                </TableWrapper>
              )}
            </Tab>
          </StyledTabs>
        </BodyWrapper>
      </Container>
    </Main>
  )
}

export default History
