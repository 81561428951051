import { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Tab } from 'react-bootstrap'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage'
import Main from '../../components/Main'
import StyledTab from '../../components/Tab'
import Button from '../../components/Button'
import Pagination from '../../components/Pagination'
import Loading from '../../components/Loading'
import TokenInfo from './components/TokenInfo'
import DepositTokenModal from './components/DepositTokenModal'
import UnlockTokenModal from './components/UnlockTokenModal'
import DepositNFTsModal from './components/DepositNFTsModal'
import LandListView from '../../components/NftItem/LandListView'
import NFT from '../../types/NFT'
import Land from '../../types/Land'
import { toWei } from '../../utils'
import {
  useActiveWeb3React,
  ApprovalState,
  useApproveCallback,
  NFTApprovalState,
  useApproveNFTCallback,
  useTokenContract,
  useNFTContract,
  useNFTsInGame,
} from '../../hooks'
import config from '../../config/config.json'
import { EmptyText, TokensWrapper, ButtonsRow, ButtonsWrapper } from './Styled'
import SectionTitle from '../../components/SectionTitle'
// @ts-ignore
import Play from '../../assets/images/play.png'
import BigNumber from 'bignumber.js'
import { AccountCol } from '../ManagerAccount/Styled'
import ConnectModal from '../../components/AccountButton/ConnectModal'

BigNumber.config({ EXPONENTIAL_AT: [-100, 100] })

function Synchronize(): JSX.Element {
  const [isFetchingNFTs, setFetchingNFTs] = useState(false)
  const [isAccountMapped, setAccountMapped] = useState(false)
  const [showConnectModal, setShowConnectModal] = useState(false)

  const [landInGame, setLandInGame] = useState<Land[]>([])
  const [paginatedLands, setPaginatedLands] = useState<Land[]>([])
  const [itemsInGame, setItemsInGame] = useState<NFT[]>([])
  const [paginatedItems, setPaginatedItems] = useState<NFT[]>([])
  const [hplAvailable, setHPLAvailable] = useState<any>(0)
  const [hpwAvailable, setHPWAvailable] = useState<any>(0)

  const [hplSpent, setHPLSpent] = useState<any>(0)
  const [hpwSpent, setHPWSpent] = useState<any>(0)

  const [showDepositTokenModal, setShowDepositTokenModal] = useState(false)
  const [showDepositLandsModal, setShowDepositLandsModal] = useState(false)
  const [showDepositItemsModal, setShowDepositItemsModal] = useState(false)
  const [showUnlockTokenModal, setShowUnlockTokenModal] = useState(false)
  const { account, chainId } = useActiveWeb3React()

  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]

  const landContract = useNFTContract(config.contracts[networkId].LandNFT)
  const hplContract = useTokenContract(config.contracts[networkId].HPL)
  const hpwContract = useTokenContract(config.contracts[networkId].HPW)

  const [needApproveNFT, setNeedApproveNFT] = useState(true)
  const [isApprovingNFT, setApprovingNFT] = useState(false)
  const [approvalNFT, approveNFTCallback] = useApproveNFTCallback(
    config.contracts[networkId].LandNFT,
    config.contracts[networkId].GameControl,
  )

  const [isApprovingHPL, setApprovingHPL] = useState(false)
  const [approvalHPL, approveHPLCallback] = useApproveCallback(
    config.contracts[networkId].HPL,
    config.contracts[networkId].GameControl,
  )

  const [isApprovingHPW, setApprovingHPW] = useState(false)
  const [approvalHPW, approveCallbackHPW] = useApproveCallback(
    config.contracts[networkId].HPW,
    config.contracts[networkId].GameControl,
  )

  const nftsInGameCallback = useNFTsInGame(account, 'ingame')

  const onApproveNFT = async () => {
    try {
      setApprovingNFT(true)
      const receipt = await approveNFTCallback()

      if (receipt && landContract) {
        toast.success(<ToastMessage color="success" bodyText="Let's play!!" />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })

        const _isApprovedForAll = await landContract.methods
          .isApprovedForAll(account, config.contracts[networkId].GameControl)
          .call()

        if (_isApprovedForAll) {
          setNeedApproveNFT(false)
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve. Please try again." />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApprovingNFT(false)
    }
  }

  const onApproveHPL = async () => {
    try {
      setApprovingHPL(true)
      const receipt = await approveHPLCallback()

      if (receipt && hplContract) {
        const _allowance = await hplContract.methods.allowance(account, config.contracts[networkId].GameControl).call()
        if (toWei(_allowance).gt(toWei(0))) {
          toast.success(<ToastMessage color="success" bodyText="Now you can synchronized HPL" />, {
            toastId: 'onApprove',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve. Please try again." />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApprovingHPL(false)
    }
  }

  const onApproveHPW = async () => {
    try {
      setApprovingHPW(true)
      const receipt = await approveCallbackHPW()

      if (receipt && hpwContract) {
        const _allowance = await hpwContract.methods.allowance(account, config.contracts[networkId].GameControl).call()

        if (toWei(_allowance).gt(toWei(0))) {
          toast.success(<ToastMessage color="success" bodyText="Approve HPW successfully" />, {
            toastId: 'onApproveHPW',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve HPW. Please try again." />, {
          toastId: 'onApproveHPW',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApprovingHPW(false)
    }
  }

  const fetchMasterBalance = async () => {
    try {
      if (account) {
        const response = await axios.get(`${apiURL}/users/playerInfo/${account}`)
        if (response.status === 200 && response.data) {
          const {
            isMaster: _isMaster,
            totalHPLDeposit: _totalHPLDeposit,
            totalHPWDeposit: _totalHPWDeposit,
            totalHPLSpent: _totalHPLSpent,
            totalHPWSpent: _totalHPWSpent,
            totalHPLWithdraw: _totalHPLWithdraw,
            totalHPWWithdraw: _totalHPWWithdraw,
          } = response.data
          if (_isMaster) {
            setAccountMapped(true)
          }
          setHPLSpent(_totalHPLSpent)
          setHPWSpent(_totalHPWSpent)
          setHPLAvailable(
            _totalHPLDeposit >= 0
              ? new BigNumber(_totalHPLDeposit)
                  .minus(new BigNumber(_totalHPLWithdraw))
                  .minus(new BigNumber(_totalHPLSpent))
              : 0,
          )
          setHPWAvailable(
            _totalHPWDeposit >= 0
              ? new BigNumber(_totalHPWDeposit)
                  .minus(new BigNumber(_totalHPWWithdraw))
                  .minus(new BigNumber(_totalHPWSpent))
              : 0,
          )
        }
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const fetchNFTsInGame = async () => {
    try {
      setFetchingNFTs(true)
      const response = await nftsInGameCallback()
      const { lands: _lands, items: _items } = response

      if (_lands && _items) {
        setLandInGame(_lands)
        setPaginatedLands(_lands)
        setItemsInGame(_items)
        setPaginatedItems(_items)
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setFetchingNFTs(false)
    }
  }

  const onRefresh = async () => {
    try {
      await fetchMasterBalance()
      await fetchNFTsInGame()
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchMasterBalance()
    fetchNFTsInGame()
  }, [account, chainId])

  const onLandsPageChange = _lands => {
    setPaginatedLands(_lands)
  }

  const onItemsPageChange = _items => {
    setPaginatedItems(_items)
  }

  const onRevoke = async () => {
    if (account && landContract && hplContract && hpwContract) {
      await landContract.methods
        .setApprovalForAll(config.contracts[networkId].GameControl, false)
        .send({ from: account })
      setNeedApproveNFT(true)
      await hplContract.methods.approve(config.contracts[networkId].GameControl, 0).send({ from: account })
      await hpwContract.methods.approve(config.contracts[networkId].GameControl, 0).send({ from: account })
    }
  }

  const onOpenDepositNFTsModal = (type: string) => {
    if (needApproveNFT && approvalNFT !== NFTApprovalState.APPROVED) {
      toast.error(<ToastMessage color="error" bodyText="Please approve the game contract first." />, {
        toastId: 'onOpenDepositNFTsModal',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        transition: Zoom,
      })
    } else {
      if (type === 'land') {
        setShowDepositLandsModal(true)
      } else {
        setShowDepositItemsModal(true)
      }
    }
  }

  return (
    <Main>
      <Container>
        {account ? (
          <>
            {isAccountMapped ? (
              <>
                {!isFetchingNFTs ? (
                  <>
                    <SectionTitle subTitle="Play To Earn" bgImage={Play} />
                    <Row className="pb-5 justify-content-center">
                      <StyledTab defaultActiveKey="lands">
                        <Tab eventKey="lands" title="Lands">
                          <ButtonsRow>
                            <Col>
                              {needApproveNFT && approvalNFT !== NFTApprovalState.APPROVED ? (
                                <Button disabled={isApprovingNFT} loading={isApprovingNFT} onClick={onApproveNFT}>
                                  Approve Lands
                                </Button>
                              ) : (
                                <>
                                  <p>Lands need to be synchronized to assign for scholar account</p>
                                  <Button onClick={() => onOpenDepositNFTsModal('land')}>Synchronize</Button>
                                  <DepositNFTsModal
                                    show={showDepositLandsModal}
                                    onHide={() => setShowDepositLandsModal(false)}
                                    onRefresh={onRefresh}
                                    type="land"
                                  />
                                </>
                              )}
                            </Col>
                          </ButtonsRow>
                          {landInGame.length > 0 ? (
                            <Row>
                              {paginatedLands.map(land => {
                                return (
                                  <Col key={land.tokenId} md={6} lg={6} xl={4} xxl={3}>
                                    <LandListView layout="grid" page="playtoearn" land={land} onRefresh={onRefresh} />
                                  </Col>
                                )
                              })}
                              <Pagination items={landInGame} onPageChange={onLandsPageChange} pageSize={12} />
                            </Row>
                          ) : (
                            <EmptyText>No lands synchronized with the game.</EmptyText>
                          )}
                        </Tab>
                        <Tab eventKey="items" title="Items">
                          <ButtonsRow>
                            <Col>
                              {needApproveNFT && approvalNFT !== NFTApprovalState.APPROVED ? (
                                <Button disabled={isApprovingNFT} loading={isApprovingNFT} onClick={onApproveNFT}>
                                  Approve Items
                                </Button>
                              ) : (
                                <>
                                  <p>Items need to be synchronized to assign for scholar account</p>
                                  <Button onClick={() => onOpenDepositNFTsModal('item')}>Synchronize</Button>
                                  <DepositNFTsModal
                                    show={showDepositItemsModal}
                                    onHide={() => setShowDepositItemsModal(false)}
                                    onRefresh={onRefresh}
                                    type="item"
                                  />
                                </>
                              )}
                            </Col>
                          </ButtonsRow>
                          {itemsInGame.length > 0 ? (
                            <Row>
                              {paginatedItems.map(item => {
                                return (
                                  <Col key={item.tokenId} md={6} xl={4} className="mb-5">
                                    <p>NFT Item</p>
                                  </Col>
                                )
                              })}
                              <Pagination items={itemsInGame} onPageChange={onItemsPageChange} pageSize={12} />
                            </Row>
                          ) : (
                            <EmptyText>No items synchronized with the game.</EmptyText>
                          )}
                        </Tab>
                        <Tab eventKey="tokens" title="Wallet">
                          <Row className="justify-content-center">
                            <Col md={11} lg={8}>
                              <TokensWrapper>
                                <Row>
                                  <h2 className="text-center mb-3">Wallet</h2>
                                  <Col md={6} className="mb-3 mb-md-0">
                                    <TokenInfo
                                      tokenName="HPL"
                                      depositedAmount={new BigNumber(hplAvailable).dividedBy(1e18).toFixed(3)}
                                    />
                                  </Col>
                                  <Col md={6} className="mb-3 mb-md-0">
                                    <TokenInfo
                                      tokenName="HPW"
                                      depositedAmount={new BigNumber(hpwAvailable).dividedBy(1e18).toFixed(3)}
                                    />
                                  </Col>
                                  <Col md={12} className="mt-3">
                                    <hr />
                                    <ButtonsWrapper>
                                      {approvalHPL !== ApprovalState.APPROVED ||
                                      approvalHPW !== ApprovalState.APPROVED ? (
                                        <>
                                          {approvalHPL !== ApprovalState.APPROVED && (
                                            <Button loading={isApprovingHPL} onClick={onApproveHPL}>
                                              Approve HPL
                                            </Button>
                                          )}
                                          {approvalHPW !== ApprovalState.APPROVED && (
                                            <Button color="secondary" loading={isApprovingHPW} onClick={onApproveHPW}>
                                              Approve HPW
                                            </Button>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <Button onClick={() => setShowDepositTokenModal(true)}>Synchronize</Button>
                                          <Button
                                            disabled={Number(hplAvailable) === 0 && Number(hpwAvailable) === 0}
                                            onClick={() => setShowUnlockTokenModal(true)}
                                            color="secondary"
                                          >
                                            Unlock
                                          </Button>
                                        </>
                                      )}
                                    </ButtonsWrapper>
                                  </Col>
                                </Row>
                                <DepositTokenModal
                                  show={showDepositTokenModal}
                                  onHide={() => setShowDepositTokenModal(false)}
                                  onRefresh={onRefresh}
                                />
                                <UnlockTokenModal
                                  _hplSpent={hplSpent}
                                  _hpwSpent={hpwSpent}
                                  hplAmount={hplAvailable}
                                  hpwAmount={hpwAvailable}
                                  show={showUnlockTokenModal}
                                  onHide={() => setShowUnlockTokenModal(false)}
                                  onRefresh={onRefresh}
                                />
                              </TokensWrapper>
                            </Col>
                          </Row>
                        </Tab>
                      </StyledTab>
                    </Row>
                  </>
                ) : (
                  <Loading />
                )}
              </>
            ) : (
              <Row className="justify-content-center">
                <Row className="justify-content-center">
                  <AccountCol xs={11} md={8} className="text-center">
                    <p className="mb-0">
                      To create sub-accounts, you need to <Link to="/dashboard">create a master account here</Link>
                    </p>
                  </AccountCol>
                </Row>
              </Row>
            )}
          </>
        ) : (
          <Row className="justify-content-center">
            <Row className="justify-content-center">
              <AccountCol xs={11} md={8} className="text-center">
                <h5 className="mb-3">Looks like your wallet is not connected.</h5>
                <p className="mb-5">Connect your wallet to continue</p>
                <Button onClick={() => setShowConnectModal(true)}>Connect Wallet</Button>
                <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
              </AccountCol>
            </Row>
          </Row>
        )}

        {process.env.NODE_ENV !== 'production' && (
          <div className="mb-3 text-center">
            <Button onClick={onRevoke}>Revoke</Button>
          </div>
        )}
      </Container>
    </Main>
  )
}

export default Synchronize
