import styled from 'styled-components/macro'

const SectionTitleWrapper = styled.div.attrs(props => ({
  // @ts-ignore
  type: props.type,
}))`
  position: relative;
  padding-bottom: 40px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 70px;
  }
`
const StyledScreenReader = styled.h2`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
`

const ImageTitle = styled.img`
  margin-bottom: 15px;
`

const StyledH2 = styled.h2`
  font-size: 24px;

  @media (min-width: 768px) {
    font-size: 30px;
  }
`
interface ISectionTitleProps {
  bgImage?: string
  subTitle?: string
  children?: string | JSX.Element | JSX.Element[]
}

function SectionTitle(props: ISectionTitleProps): JSX.Element {
  const { bgImage, subTitle, children } = props

  return (
    <SectionTitleWrapper>
      <StyledScreenReader>{subTitle}</StyledScreenReader>
      {bgImage && <ImageTitle src={bgImage} alt={subTitle} />}
      {children && <StyledH2>{children}</StyledH2>}
    </SectionTitleWrapper>
  )
}

export default SectionTitle
