import styled from 'styled-components/macro'
import { Row, FormControl, InputGroup } from 'react-bootstrap'

export const EmptyText = styled.p`
  padding: 6rem 0;
  text-align: center;
`
export const ButtonsRow = styled(Row)`
  justify-content: center;
  text-align: center;
  margin-bottom: 2rem;

  button {
    margin: 0.5rem;
  }
`
// Tokens
export const TokensWrapper = styled.div`
  background-color: #110f22;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 3rem 2rem;
`
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;

  button {
    margin-right: 1rem;
  }
`
export const BalanceText = styled.p`
  text-align: right;
  font-size: 15px;
  font-weight: 400;
`
export const Input = styled(FormControl)`
  box-shadow: none !important;
  border-radius: 0;
  border: ${props => props.borderwidth || '2px'} solid rgba(255, 255, 255, 0.3);
  background-color: transparent !important;
  color: #fff !important;
  font-size: 20px;
  padding: ${props => props.paddinginput || '.375rem .75rem'};

  &:focus,
  &:active {
    border-color: #fff;
  }

  &:placeholder-shown + p {
    display: none;
  }
`

// NFTs item in modal
export const NFTList = styled.div`
  height: 350px;
  overflow: auto;
`
export const NFTItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 15px 5px;
  border-bottom: 1px solid #ffffff1f;

  > div {
    flex: 1;
  }

  &.is-selected {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .form-check {
    display: flex;
    align-self: center;
    padding: 0;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    flex: 0 0 20px;

    .form-check-input {
      float: none;
      margin: 0 0.5rem 0 0;
      cursor: pointer;

      &:checked {
        background-color: ${props => props.theme.color.orange[400]};
        border-color: ${props => props.theme.color.orange[400]};
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem ${props => props.theme.color.orange[400]}40;
      }
    }

    .form-check-label {
      cursor: pointer;
    }
  }
`
export const SmallNFTID = styled.span`
  margin-right: 0.5rem;
`
export const DepositAllWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;

  .form-check {
    display: flex;
    align-items: center;
    padding: 0;
    cursor: pointer;
    color: #fff;

    .form-check-input {
      float: none;
      margin: 0 0.5rem 0 0;
      cursor: pointer;

      &:checked {
        background-color: ${props => props.theme.color.orange[400]};
        border-color: ${props => props.theme.color.orange[400]};
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem ${props => props.theme.color.orange[400]}40;
      }
    }

    .form-check-label {
      cursor: pointer;
    }
  }
`

// Deposit Modal
export const DepositWrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  row-gap: 20px;
`
export const DepositInputGroup = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: 10px;
  background: transparent;
  z-index: 1;
  border: 1px solid #fff;
`
export const DepositInputInfo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0;
`
export const StyledInputGroup = styled(InputGroup)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
`
