import { Modal as BootstrapModal } from 'react-bootstrap'
import styled from 'styled-components/macro'
import ModalBg from '../../assets/images/modal/modal-bg.png'
import TitleBg from '../../assets/images/modal/title-bg.png'
import BtnClose from '../../assets/images/modal/btn-close.png'

const StyledModal = styled(BootstrapModal)`
  .modal-content {
    background-image: url(${ModalBg});
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 600px;
    background-color: transparent !important;
    border: none;
  }

  .modal-header {
    position: absolute;
    background-image: url(${TitleBg});
    justify-content: center;
    align-items: center;
    border-bottom: none;
    display: flex;
    width: 260px;
    height: 80px;
    left: 50%;
    transform: translate(-50%, -20px);

    .btn-close {
      position: absolute;
      background-image: url(${BtnClose});
      background-size: cover;
      width: 30px;
      height: 30px;
      transform: translate(10rem, -1rem);
    }
  }

  .modal-body {
    border-radius: 0;
    padding: 4rem;
  }

  .modal-footer {
    border-top: 0;
  }
`

const ModalTitle = styled(BootstrapModal.Title)`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 43.39%, rgba(0, 0, 0, 0) 100%), #790017;
  position: relative;
  border-radius: 18px;
  width: 100%;
  height: 45px;
  text-align: center;
`

const TextTitle = styled.p`
  font-size: 20px;
  color: #fff;
  margin-bottom: 0;
  margin-top: 8px;
`

interface IModalProps {
  size?: string
  className?: any
  show: boolean
  hideTitle?: boolean
  title: string | JSX.Element | JSX.Element[]
  children?: any
  button?: JSX.Element | JSX.Element[]
  onHide: () => void
}

function Modal(props: IModalProps): JSX.Element {
  const { size, className, show, hideTitle, title, children, button, onHide } = props

  return (
    <StyledModal className={className} autoFocus centered size={size} show={show} onHide={onHide}>
      <BootstrapModal.Header closeButton>
        <ModalTitle className={hideTitle && 'screen-reader-text'}>
          <TextTitle>{title}</TextTitle>
        </ModalTitle>
      </BootstrapModal.Header>
      <BootstrapModal.Body>{children}</BootstrapModal.Body>
      {button && <BootstrapModal.Footer>{button}</BootstrapModal.Footer>}
    </StyledModal>
  )
}

export default Modal
