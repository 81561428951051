import { useState } from 'react'
// import JSEncrypt from 'jsencrypt'
import Web3 from 'web3'
import axios, { AxiosError } from 'axios'
import { Form, Col } from 'react-bootstrap'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../../components/ToastMessage'
import Button from '../../../components/Button'
import { useActiveWeb3React } from '../../../hooks'
import {
  FormInput,
  FormLabel,
  ButtonWrapper,
  LabelTitle,
  SecondFormLabel,
  FormSelect,
  UpdateFormGroup,
  InfoGroup,
  FormTextDanger,
} from '../Styled'
import config from '../../../config/config.json'

interface IChangePasswordFormProps {
  username: string
  location: string
  commission?: number
  allowChangeCommission?: boolean
  wallet: string
  children?: string | JSX.Element | JSX.Element[]
}

function ChangePasswordForm(props: IChangePasswordFormProps): JSX.Element {
  const { username, location, commission, allowChangeCommission, wallet, children } = props
  const { account, chainId, library } = useActiveWeb3React()
  const web3 = new Web3(library)
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]

  const [isRegistering, setRegistering] = useState(false)
  const [currentLocation, setCurrentLocation] = useState(location)
  const [percent, setPercent] = useState<any>(commission)
  const [password, setPassword] = useState('')
  const [passwordScore, setPasswordScore] = useState(1)
  const [isPasswordMatched, setPasswordMatched] = useState(false)

  const onLocationChange = e => {
    const { value } = e.currentTarget
    setCurrentLocation(value)
  }

  const onPercentChange = e => {
    const { value } = e.currentTarget
    setPercent(value)
  }

  const onPasswordChange = e => {
    const { value } = e.currentTarget
    setPassword(value)
  }

  const onConfirmPasswordChange = e => {
    const { value } = e.currentTarget
    setPasswordMatched(value === password)
  }

  const onChangeScore = score => {
    setPasswordScore(score)
  }

  const onChangePassword = async () => {
    try {
      setRegistering(true)

      if (account) {
        // const encrypt = new JSEncrypt()
        // encrypt.setPublicKey(config.public_key)
        // const hashedPassword = encrypt.encrypt(password)
        const hashedPassword = password
        const expiry = (Date.now() / 1000 + 15).toFixed(0)

        if (hashedPassword) {
          const hashedMsg = web3.utils.sha3(`${account.toLowerCase()}-${username}-${location}-${expiry}-${wallet}`)

          if (hashedMsg) {
            const signature = await web3.eth.personal.sign(hashedMsg, account, '')

            const response = await axios.post(`${apiURL}/masters/updateSubAccount`, {
              username,
              password: hashedPassword,
              location,
              percent,
              expiry,
              master: account.toLowerCase(),
              player: wallet.toLowerCase(),
              signature,
            })

            if (response.status === 200 && response.data) {
              toast.success(<ToastMessage color="success" bodyText="Update information success." />, {
                toastId: 'onChangePassword',
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: true,
                transition: Zoom,
              })
            }
          } else {
            toast.error(<ToastMessage color="error" bodyText="Could not update information." />, {
              toastId: 'onChangePassword',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            })
            throw new Error('Encrypt message failed')
          }
        } else {
          toast.error(<ToastMessage color="error" bodyText="Could not update information." />, {
            toastId: 'onChangePassword',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
          })
          throw new Error('Encrypt password failed')
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const msg = _e.response?.data.errors
        toast.error(<ToastMessage color="error" bodyText={msg ? msg.toUpperCase() : 'Could not update.'} />, {
          toastId: 'onChangePassword',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setRegistering(false)
    }
  }

  return (
    <>
      <Form>
        <UpdateFormGroup>
          <InfoGroup>
            <Form.Group controlId="formEmail">
              <FormLabel controlId="floatingFormEmail" label="Username">
                <FormInput readOnly type="text" value={username} />
              </FormLabel>
            </Form.Group>
            <Form.Group controlId="formPercent">
              <FormLabel controlId="floatingFormPercent" label="Commission for Scholar (%)">
                <FormInput
                  type="text"
                  placeholder="30"
                  readOnly={!allowChangeCommission}
                  value={percent}
                  onChange={e => onPercentChange(e)}
                />
              </FormLabel>
            </Form.Group>
            <Form.Group controlId="formPassword">
              <FormLabel controlId="floatingFormPassword" label="New Password">
                <FormInput
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={e => onPasswordChange(e)}
                />
              </FormLabel>
            </Form.Group>
          </InfoGroup>
          <InfoGroup>
            <Form.Group controlId="formWallet">
              <FormLabel controlId="floatingFormWallet" label="Scholar's Wallet">
                <FormInput
                  readOnly
                  type="text"
                  value={`${wallet.substring(0, 6)}...${wallet.substring(wallet.length - 4)}`}
                />
              </FormLabel>
            </Form.Group>
            <Form.Group controlId="location">
              <SecondFormLabel>
                <LabelTitle>Server</LabelTitle>
                <FormSelect aria-label="Server" onChange={e => onLocationChange(e)}>
                  <option value="asia">Asia</option>
                  <option value="eu">Europe</option>
                </FormSelect>
              </SecondFormLabel>
            </Form.Group>
            <Form.Group controlId="formPasswordConfirm">
              <FormLabel controlId="floatingFormPasswordConfirm" label="Confirm New Password">
                <FormInput type="password" placeholder="Confirm Password" onChange={e => onConfirmPasswordChange(e)} />
                {!isPasswordMatched && (
                  <FormTextDanger className="d-block mt-2 text-danger">Passwords do not match</FormTextDanger>
                )}
              </FormLabel>
            </Form.Group>
          </InfoGroup>
        </UpdateFormGroup>
        <ButtonWrapper>
          <Button
            disabled={isRegistering || passwordScore < 2 || !isPasswordMatched}
            loading={isRegistering}
            onClick={onChangePassword}
          >
            Update Information
          </Button>
          {children}
        </ButtonWrapper>
      </Form>
    </>
  )
}

export default ChangePasswordForm
