import styled from 'styled-components/macro'
import { Col, Form } from 'react-bootstrap'
import WalletBg from '../../assets/images/wallet.svg'
import GuildMasterBg from '../../assets/images/guild-management/guild-master.png'

export const AccountCol = styled(Col)`
  background: #110f22;
  padding: 4rem 1.5rem;
  border-radius: 10px;
  border: 1px solid #000;

  @media (min-width: 992px) {
    padding: 3.5rem 2.5rem 6rem;
  }
  h5 {
    margin-bottom: 1.5rem;
    @media (min-width: 992px) {
      margin-bottom: 3rem;
    }
  }
`
export const BalanceWrapper = styled.div`
  margin: 1rem 0;
`
export const GameAccountWrapper = styled.div`
  display: block;
`
export const FormSelect = styled(Form.Select)`
  background: transparent;
  color: #fff;
  box-shadow: none !important;
  border: 1px solid #fff;
  border-radius: 10px;
  &:focus,
  &:active {
    border-color: ${props => props.theme.color.orange[500]};
  }
`
export const FormInput = styled(Form.Control)`
  box-shadow: none !important;
  border-radius: 10px;
  border: 1px solid #fff;
  background-color: transparent !important;
  color: #fff !important;

  &:focus,
  &:active {
    border-color: ${props => props.theme.color.orange[500]};
  }

  &:placeholder-shown + small {
    display: none !important;
  }

  &[readonly] {
    opacity: 0.7;
    color: #ffffff22;
    background-color: #ffffff22 !important;

    &:focus,
    &:active {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
`
export const StyledButton = styled.button`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${WalletBg}) no-repeat center center;
  background-size: 100%;
  width: 140px;
  height: 42px;
  color: #fff;
  font-family: 'Lilita One', cursive;
  font-size: 12px;
  line-height: 1;
  border: none;
  &:hover {
    transform: translateY(-5px);
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;

  button {
    margin-right: 0.5rem;
  }
`
export const ReferralLink = styled.span`
  font-size: 12px;
  display: block;
  color: ${props => props.theme.color.orange[300]};
  margin-bottom: 1rem;
  cursor: pointer;
`

export const GuildMasterWrapper = styled.div`
  background-image: url(${GuildMasterBg});
  background-size: contain;
  background-repeat: no-repeat;
  width: fit-content;
  margin: 0 auto;
  padding: 5rem;
`

export const GuildMasterTitle = styled.p`
  color: #5a2b00;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 5px;
`

export const TextContent = styled.p`
  color: #731c00;
  font-size: 24px;
  margin: 0;
`

export const GuildMasterButtons = styled.div`
  display: flex;
  justify-content: start;
  font-size: 24px;
  padding: 10px 0;

  button:first-child {
    background: #731c00;
    border-radius: 14px;
    color: #fff;
    margin-right: 10px;
    padding: 7px 20px;

    &:hover {
      background: #fee60000;
      border: 1px solid #731c00;
      color: #731c00;
    }
  }

  button {
    background: #fee60000;
    border: 1px solid #731c00;
    border-radius: 14px;
    color: #731c00;
    padding: 7px 20px;

    &:hover {
      background: #731c00;
      color: #fff;
    }
  }
`

export const RowContent = styled.div`
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;

  &::after {
    content: '';
    position: absolute;
    border: 1px dashed #5a2b00;
    bottom: 0;
    width: 100%;
    height: 1px;
    opacity: 0.3;
  }
`
