import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'
import HeaderBg from '../../assets/images/header-bg.svg'
import BalanceBg from '../../assets/images/balance.png'

export const HeaderMain = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  background: url(${HeaderBg}) no-repeat center center;
  background-size: cover;
  @media (min-width: 992px) {
    background: none;
  }
`
export const HeaderWrapper = styled.div`
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 0 3rem;

  @media (min-width: 992px) {
    background: url(${HeaderBg}) no-repeat center center;
    background-size: contain;
    height: 96px;
  }

  @media (max-width: 991px) {
    display: flex;
  }
`
export const LogoWrapper = styled.div`
  max-width: 165px;
  margin-top: 2rem;
`
export const HeaderTopLogin = styled.div`
  align-self: center;
  z-index: 1;
`
export const LeftMenu = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`
export const RightMenu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 3rem;
  flex: 1;
`
export const Logo = styled.img`
  width: 80px;
  min-width: 80px;

  transition: all 0.5s ease;

  @media (min-width: 768px) {
    width: 120px;
  }

  @media (min-width: 1200px) {
    width: 100%;
  }
`
export const MenuLinkStyle = css`
  font-family: 'Lilita One', cursive;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: #731c00;
  padding: 10px 6px;
  display: block;
  line-height: 1;
  position: relative;
  z-index: 1;
  @media (min-width: 1200px) {
    font-size: 20px;
    padding: 10px 15px;
  }

  &:hover {
    color: ${props => props.theme.color.orange[200]};
  }

  &.active {
    color: #fff;
    text-shadow: 0px 4px 5px #b05500;
  }
`
export const MenuLink = styled(Link)`
  ${MenuLinkStyle}
`
export const MenuA = styled.a`
  ${MenuLinkStyle}
`
export const MobileMenuLink = styled(Link)`
  ${MenuLinkStyle};
  color: #fff;
  &:hover,
  &.active {
    color: #ffc11b;
  }
`
export const MobileMenuA = styled.a`
  ${MenuLinkStyle};
  color: #fff;
`
export const MobileMenu = styled.ul`
  list-style: none;
`
export const MenuToggle = styled.button`
  background: transparent;
  border: none;
  color: #fff;
  @media (min-width: 992px) {
    display: none;
  }
`
export const BalancesWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  right: 20px;
  bottom: -7.5rem;
`
export const BalanceInner = styled.div`
  background: url(${BalanceBg}) no-repeat center center;
  background-size: contain;
  padding-bottom: 11px;
  text-align: center;
  display: grid;

  @media (min-width: 768px) {
    padding: 4rem;
  }
`

export const BalanceText = styled.span`
  color: #5a2b00;
  font-size: 16px;
  margin-top: 20px;
`

export const BalanceNumber = styled.span`
  color: #731c00;
  font-size: 24px;
`
