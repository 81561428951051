import { useTable, usePagination } from 'react-table'
import { SelectOption } from '../../components/SelectBox'
import {
  StyledTable,
  HeaderTitle,
  BorderTopTable,
  BorderBottomTable,
  ActionButton,
  IndexText,
  StyledSelect,
} from './Styled'

interface IAccountTableProps {
  columns: any
  data: any
}

function AccountTable(props: IAccountTableProps): JSX.Element {
  const { columns, data } = props
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 5 } }, usePagination)

  const pageSizeOptions = [
    {
      name: 'Show 5',
      value: 5,
    },
    {
      name: 'Show 10',
      value: 10,
    },
    {
      name: 'Show 20',
      value: 20,
    },
    {
      name: 'Show 30',
      value: 30,
    },
    {
      name: 'Show 50',
      value: 50,
    },
  ] as SelectOption[]

  const onSetPageSize = e => {
    setPageSize(e)
  }

  return (
    <div>
      <BorderTopTable />
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  <HeaderTitle>{column.render('Header')}</HeaderTitle>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </StyledTable>
      <BorderBottomTable />
      {pageOptions.length > 1 && (
        <div className="d-flex justify-content-center align-items-center mt-3">
          <ActionButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </ActionButton>{' '}
          <ActionButton onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </ActionButton>{' '}
          <IndexText>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </IndexText>
          <ActionButton onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </ActionButton>{' '}
          <ActionButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </ActionButton>{' '}
          <StyledSelect
            placeholder="---"
            options={pageSizeOptions}
            onChange={value => onSetPageSize(value)}
            value={pageSize}
          />
        </div>
      )}
    </div>
  )
}

export default AccountTable
