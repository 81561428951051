import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Pagination as BootstrapPagination } from 'react-bootstrap'

const StyledPagination = styled(BootstrapPagination)`
  justify-content: center;
  padding: 0;

  .page-link {
    display: inline-block;
    margin: 0 0.125rem !important;
    background-color: transparent;
    border-width: 2px;
    border-color: #982f34;
    border-radius: 5px !important;
    padding: 0 5px;
    min-width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    color: #fff;

    @media (min-width: 768px) {
      min-width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: 15px;
    }
  }

  .page-item:hover .page-link,
  .page-item.active .page-link {
    background-color: #982f34;
    border-color: #982f34;
  }

  .page-item.disabled .page-link {
    background-color: #982f34;
    border-color: #982f34;
    color: #fff;
    opacity: 0.5;
  }
`

interface IPaginationProps {
  items: any[]
  onPageChange: (any) => void
  initialPage?: number
  pageSize?: number
}

type Pager = {
  totalItems: number
  currentPage: number
  pageSize: number
  totalPages: number
  startPage: number
  endPage: number
  startIndex: number
  endIndex: number
  pages: any[]
}

function Pagination(props: IPaginationProps): JSX.Element {
  const { items, onPageChange, initialPage, pageSize } = props
  const [pager, setPager] = useState<Pager>({
    totalItems: items.length,
    currentPage: initialPage ?? 1,
    pageSize: pageSize ?? 10,
    totalPages: Math.ceil(items.length / (pageSize ?? 10)),
    startPage: 1,
    endPage: items.length,
    startIndex: 0,
    endIndex: (pageSize ?? 10) - 1,
    pages: [],
  })

  const getPager = (totalItems: number, _currentPage: number, _pageSize: number): Pager => {
    const currentPage = _currentPage || 1
    const __pageSize = _pageSize || 10
    const totalPages = Math.ceil(totalItems / __pageSize)

    let startPage = 0
    let endPage = 0

    if (totalPages <= 5) {
      // less than 5 total pages so show all
      startPage = 1
      endPage = totalPages
    } else {
      // more than 5 total pages so calculate start and end pages
      if (currentPage <= 4) {
        startPage = 1
        endPage = 5
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4
        endPage = totalPages
      } else {
        startPage = currentPage - 2
        endPage = currentPage + 2
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * __pageSize
    const endIndex = Math.min(startIndex + __pageSize - 1, totalItems - 1)

    // create an array of pages to ng-repeat in the pager control
    // @ts-ignore
    const pages = [...Array(endPage + 1 - startPage).keys()].map(i => startPage + i)

    return {
      totalItems,
      currentPage,
      pageSize: __pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    }
  }

  const setPage = (page: number) => {
    if (page < 1 || page > pager.totalPages) {
      return
    }

    // get new pager object for specified page
    const _pager = getPager(items.length, page, pageSize ?? 10)
    setPager(_pager)

    // get new page of items from items array
    const pageOfItems = items.slice(_pager.startIndex, _pager.endIndex + 1)

    // call change page function in parent component
    onPageChange(pageOfItems)
  }

  useEffect(() => {
    setPage(initialPage ?? 1)
  }, [items])

  return (
    <>
      {pager.pages.length > 0 && (
        <StyledPagination>
          <StyledPagination.Prev disabled={pager.currentPage === 1} onClick={() => setPage(pager.currentPage - 1)} />

          {pager.pages.map((page, index) => {
            return (
              <StyledPagination.Item key={index} active={pager.currentPage === page} onClick={() => setPage(page)}>
                {page}
              </StyledPagination.Item>
            )
          })}

          <StyledPagination.Next
            disabled={pager.currentPage === pager.totalPages}
            onClick={() => setPage(pager.currentPage + 1)}
          />
        </StyledPagination>
      )}
    </>
  )
}

Pagination.defaultProps = {
  initialPage: 1,
  pageSize: 10,
}

export default Pagination
