import styled, { keyframes } from 'styled-components/macro'
import { CgSpinner } from 'react-icons/cg'

export const LoadingWrapper = styled.div`
  padding-bottom: 30vh;
  text-align: center;
  color: ${props => props.theme.color.orange[300]};
`
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
export const LoadingIcon = styled(CgSpinner)`
  animation: ${spin} 0.75s linear infinite;
  margin-right: 0.5rem;
`

function Loading(): JSX.Element {
  return (
    <LoadingWrapper>
      <LoadingIcon size={20} />
      Please wait
    </LoadingWrapper>
  )
}

export default Loading
