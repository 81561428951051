import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import { useTokenContract } from './useContract'
import { fromWei } from '../utils'

export const useTokenBalance = (
  tokenAddress: string | undefined,
  account: string | null | undefined,
): (() => Promise<number>) => {
  const tokenContract = useTokenContract(tokenAddress)

  const callback = useCallback(async (): Promise<number> => {
    let result = 0
    try {
      if (account && tokenContract) {
        const _balance = await tokenContract.methods.balanceOf(account).call()
        const _balanceBN = fromWei(_balance)
        result = _balanceBN.gt(new BigNumber(0)) ? _balanceBN.minus(new BigNumber(1e-9)).toNumber() : 0
        result = result > 0 ? result : 0
      }
    } catch (error: any) {
      console.error(error)
    }

    return result
  }, [account, tokenContract])

  return callback
}
