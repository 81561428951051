import styled from 'styled-components/macro'
import AppBg from '../../assets/images/app-bg.jpg'

const Section = styled.div.attrs(props => ({
  // @ts-ignore
  bgImage: props.bgImage,
}))`
  background-image: url(${props => (props.bgImage ? props.bgImage : AppBg)});
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  padding: 140px 0 80px;
  min-height: 100vh;
  @media (min-width: 768px) {
    padding: 180px 0 80px;
  }
  @media (min-width: 1200px) {
    padding: 220px 0 80px;
  }
`

interface IMainProps {
  bgImage?: string
  children?: string | JSX.Element | JSX.Element[]
}

function Main(props: IMainProps): JSX.Element {
  const { bgImage, children } = props

  return <Section bgImage={bgImage}>{children}</Section>
}

export default Main
