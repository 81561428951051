import { useCallback } from 'react'
import axios from 'axios'
import { useNFTContract, useMarketPlaceContract } from './useContract'
import { useActiveWeb3React } from './useWeb3'
import Land from '../types/Land'
import Item from '../types/Item'
import config from '../config/config.json'
import { fromWei, decodeNFTFeatures } from '../utils'

export const useNFTsApi = (account: string | null | undefined): (() => Promise<any>) => {
  const { chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]

  const callback = useCallback(async (): Promise<any> => {
    try {
      if (!account) {
        return { lands: [], items: [] }
      }
      const response = await axios.get(`${apiURL}/tokens/${account}`)

      if (response.status === 200 && response.data) {
        const { tokens } = response.data
        const lands = tokens.filter(token => token.tokenType === 'land')
        const items = tokens.filter(token => token.tokenType === 'item')
        return { lands, items }
      }
    } catch (error: any) {
      console.error(error)
      return {}
    }
  }, [account])

  return callback
}

export const useNFTs = (account: string | null | undefined): (() => Promise<any>) => {
  const { chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const nftContract = useNFTContract(config.contracts[networkId].NFT)
  const marketplaceContract = useMarketPlaceContract(config.contracts[networkId].Market)

  const callback = useCallback(async (): Promise<any> => {
    const tokens = []
    const lands: Land[] = []
    const items: Item[] = []

    try {
      if (account && nftContract && marketplaceContract) {
        const balance = await nftContract.methods.balanceOf(account).call()

        for (let i = 0; i < balance; i++) {
          const tokenId = await nftContract.methods.tokenOfOwnerByIndex(account, i).call()
          // @ts-ignore
          tokens.push(Number(tokenId))
        }
        tokens.sort((a, b) => {
          return a - b
        })

        for (let j = 0; j < balance; j++) {
          // @ts-ignore
          const features = await nftContract.methods.getTokenFeatures(tokens[j]).call()
          const decodedFeatures = decodeNFTFeatures(features)
          const item: { type: string; tokenId: number } = { type: '', tokenId: -1 }

          for (let k = 0; k < decodedFeatures.length; k++) {
            const feature = decodedFeatures[k]
            item['tokenId'] = Number(tokens[j])
            item['owner'] = account
            item[feature.name] = feature.value

            if (item.type === 'car') {
              if (lands.findIndex(c => c.tokenId === item.tokenId) < 0) {
                // @ts-ignore
                lands.push(item as Land)
              }
            } else if (item.type === 'gun' || item.type === 'rocket') {
              if (items.findIndex(w => w.tokenId === item.tokenId) < 0) {
                // @ts-ignore
                items.push(item as Item)
              }
            }
          }
        }
        console.info({ lands, items })

        // Find all sales
        const allSales = await marketplaceContract.methods.getActiveSales().call()

        for (let i = 0; i < allSales.length; i++) {
          if (allSales[i].owner === account) {
            const features = await nftContract.methods.getTokenFeatures(allSales[i].tokenId).call()
            const decodedFeatures = decodeNFTFeatures(features)
            const item = {
              tokenId: Number(allSales[i].tokenId),
              owner: allSales[i].owner,
              price: fromWei(allSales[i].price).toNumber(),
              isSale: true,
            }

            for (let j = 0; j < decodedFeatures.length; j++) {
              const feature = decodedFeatures[j]
              item[feature.name] = feature.value

              // @ts-ignore
              if (item.type === 'car') {
                if (lands.findIndex(c => c.tokenId === item.tokenId) < 0) {
                  // @ts-ignore
                  lands.push(item as Land)
                }
                // @ts-ignore
              } else if (item.type === 'gun' || item.type === 'rocket') {
                if (items.findIndex(w => w.tokenId === item.tokenId) < 0) {
                  // @ts-ignore
                  items.push(item as Item)
                }
              }
            }
          }
        }
      }
    } catch (error: any) {
      console.error(error)
    }
    return { lands, items }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, nftContract])

  return callback
}
