import { useState, useEffect, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useTokenContract } from './useContract'

export const useTokenAllowance = (tokenAddress?: string, owner?: string, spender?: string): BigNumber | undefined => {
  const [allowance, setAllowance] = useState<BigNumber>(new BigNumber(0))
  const tokenContract = useTokenContract(tokenAddress)

  const fetchAllowance = async () => {
    try {
      if (tokenContract) {
        const _allowance = await tokenContract.methods.allowance(owner, spender).call()
        const _allowanceBN = new BigNumber(_allowance)
        setAllowance(_allowanceBN)
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchAllowance()
  }, [tokenAddress])

  return useMemo(() => {
    return allowance
  }, [tokenAddress, allowance])
}
