import styled from 'styled-components/macro'
import { Table } from 'react-bootstrap'
import SelectBox from '../../components/SelectBox'

export const HeaderWrapper = styled.div`
  display: flex;
  position: relative;
  padding-left: 10rem;
  margin-bottom: -30px;
`

export const GoBackBtn = styled.div`
  margin-top: 15px;
  margin-right: 30px;
  cursor: pointer;
`

export const SectionTitles = styled.h2`
  font-size: 100px;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
  color: #731c00;
`

export const BodyWrapper = styled.div`
  background: #ffe5cd;
  border-radius: 14px;
  padding: 20px 20px 40px 20px;
  z-index: 1;
`

export const TableWrapper = styled.div`
  background: #fff;
  height: auto;
  border-radius: 14px;
  padding: 30px;
`

export const BorderTopTable = styled.div`
  background: #5a2b00;
  height: 10px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
`

export const BorderBottomTable = styled.div`
  background: #5a2b00;
  height: 10px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
`

export const StyledTable = styled(Table)`
  background: rgba(233, 195, 146, 0.3);
  text-align: center;
  font-size: 16px;
  margin: 0;

  th {
    background: #5a2b00;
    border: 1px dashed #ff931d;
    border-top: none;
    padding: 20px;
  }

  td {
    color: #5a2b00;
    border: 1px dashed #5a2b00;
  }

  a {
    color: #731c00;
  }
`

export const HeaderTitle = styled.p`
  color: #ff931d;
  margin-bottom: 0;
`

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const ActionButton = styled.button`
  color: #ff931d;
  background: #5a2b00;
  border: none;
  border-radius: 4px;
  margin: 0 2px;
`

export const IndexText = styled.span`
  color: #731c00;
  margin: 0 10px;
`

export const StyledSelect = styled(SelectBox)`
  margin-left: 10px;
`

export const EmptyText = styled.p`
  padding: 6rem 0;
  text-align: center;
  color: #5a2b00;
`
