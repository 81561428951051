import { useState, useEffect, useMemo, useCallback } from 'react'
import { useActiveWeb3React } from './useWeb3'
import { useNFTContract } from './useContract'

export enum NFTApprovalState {
  UNKNOWN = 'UNKNOWN',
  NOT_APPROVED = 'NOT_APPROVED',
  APPROVED = 'APPROVED',
}

export const useApprovedForAll = (contractAddress?: string, account?: string, spender?: string): boolean => {
  const [isApprovedForAll, setApproveForAll] = useState(false)
  const nftContract = useNFTContract(contractAddress)

  const fetchState = async () => {
    try {
      if (account && nftContract) {
        const _isApprovedForAll = await nftContract.methods.isApprovedForAll(account, spender).call()
        setApproveForAll(_isApprovedForAll)
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchState()
  }, [account, spender])

  return useMemo(() => {
    return isApprovedForAll
  }, [account, spender, isApprovedForAll])
}

export const useApproveNFTCallback = (
  contractAddress?: string,
  spender?: string,
  tokenId?: number,
): [NFTApprovalState, () => Promise<any>] => {
  const { account } = useActiveWeb3React()
  const nftContract = useNFTContract(contractAddress)
  const approved = useApprovedForAll(contractAddress, account ?? undefined, spender)

  const approvalState = useMemo(() => {
    if (!account) return NFTApprovalState.UNKNOWN

    return approved ? NFTApprovalState.APPROVED : NFTApprovalState.NOT_APPROVED
  }, [approved, spender])

  const approve = useCallback(async (): Promise<void> => {
    if (!contractAddress) {
      console.error('no token')
      return
    }

    if (!nftContract) {
      console.error('nftContract is null')
      return
    }

    // if (!tokenId) {
    //   console.error('missing token id to approve')
    //   return
    // }

    if (!spender) {
      console.error('no spender')
      return
    }

    // return nftContract.methods.approve(spender, tokenId).send({ from: account })
    return nftContract.methods.setApprovalForAll(spender, true).send({ from: account })
  }, [NFTApprovalState, account, contractAddress, nftContract, spender, tokenId])

  return [approvalState, approve]
}
