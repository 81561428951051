import { black, orange, purple } from './colors'

const theme = {
  color: {
    black,
    orange,
    purple,
  },
}

export default theme
