import { useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import { Container, Row } from 'react-bootstrap'
import SectionTitle from '../../components/SectionTitle'
import Main from '../../components/Main'
import AccountTable from '../ManagerAccount/AccountTable'
import DashboardBg from '../../assets/images/app-bg.jpg'
import config from '../../config/config.json'
import { useActiveWeb3React } from '../../hooks'
import { AccountCol } from '../ManagerAccount/Styled'
import BigNumber from 'bignumber.js'
import { useParams } from 'react-router-dom'

BigNumber.config({ EXPONENTIAL_AT: [-100, 100] })

function CheckAccount(): JSX.Element {
  const [listAccount, setListAccount] = useState<any>([])
  const [hplSpent, setHPLSpent] = useState<any>(0)
  const [hpwSpent, setHPWSpent] = useState<any>(0)
  const [hplScholarRevenue, setHPLScholarRevenue] = useState<any>(0)
  const [hpwScholarRevenue, setHPWScholarRevenue] = useState<any>(0)
  const [hplRevenue, setHPLRevenue] = useState<any>(0)
  const [hpwRevenue, setHPWRevenue] = useState<any>(0)
  const [hplRevenueClaimed, setHPLRevenueClaimed] = useState<any>(0)
  const [hpwRevenueClaimed, setHPWRevenueClaimed] = useState<any>(0)

  // @ts-ignore
  const { slug } = useParams()
  const address = slug

  const { chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'id',
        Cell: ({ row }) => <div>{Number(row.id) + 1}</div>,
      },
      {
        Header: 'Information',
        id: 'information',
        accessor: d => (
          <div style={{ padding: '15px 10px' }}>
            <p style={{ marginBottom: '5px' }}>{d.username}</p>
            <a href={`${config.explorerURL[networkId]}/address/${d.address}`} target="_blank">
              {d.address ? `${d.address.substring(0, 6)}...${d.address.substring(d.address.length - 4)}` : ''}
            </a>
            <p style={{ marginBottom: '0' }}>Location: {d.location}</p>
          </div>
        ),
      },
      {
        Header: 'Commission',
        id: 'commission',
        accessor: d => <p className="text-center mb-0">{d.commission ? `${d.commission} %` : 'N/A'}</p>,
      },
      {
        Header: 'Deposit',
        id: 'deposit',
        accessor: d => (
          <>
            <span>{d.totalHPLDeposit >= 0 ? new BigNumber(d.totalHPLDeposit).dividedBy(1e18).toFixed(2) : 0} HPL</span>
            <br />
            <span>{d.totalHPWDeposit >= 0 ? new BigNumber(d.totalHPWDeposit).dividedBy(1e18).toFixed(2) : 0} HPW </span>
          </>
        ),
      },
      {
        Header: 'Spent',
        id: 'spent',
        accessor: d => (
          <>
            <span>{d.totalHPLSpent >= 0 ? new BigNumber(d.totalHPLSpent).dividedBy(1e18).toFixed(2) : 0} HPL</span>
            <br />
            <span>{d.totalHPWSpent >= 0 ? new BigNumber(d.totalHPWSpent).dividedBy(1e18).toFixed(2) : 0} HPW </span>
          </>
        ),
      },
      {
        Header: 'Revenue',
        id: 'revenue',
        accessor: d => (
          <>
            <span>{d.totalHPLReward >= 0 ? new BigNumber(d.totalHPLReward).dividedBy(1e18).toFixed(2) : 0} HPL</span>
            <br />
            <span>{d.totalHPWReward >= 0 ? new BigNumber(d.totalHPWReward).dividedBy(1e18).toFixed(2) : 0} HPW </span>
          </>
        ),
      },
      {
        Header: 'Unrealized Revenue',
        id: 'unrealizedRevenue',
        accessor: d => (
          <>
            <span>
              {d.totalHPLRewardClaimed >= 0
                ? new BigNumber(d.totalHPLReward)
                    .minus(new BigNumber(d.totalHPLRewardClaimed))
                    .dividedBy(1e18)
                    .toFixed(2)
                : 0}{' '}
              HPL
            </span>
            <br />
            <span>
              {d.totalHPWRewardClaimed >= 0
                ? new BigNumber(d.totalHPWReward)
                    .minus(new BigNumber(d.totalHPWRewardClaimed))
                    .dividedBy(1e18)
                    .toFixed(2)
                : 0}{' '}
              HPW{' '}
            </span>
          </>
        ),
      },
      {
        Header: 'Scholar Reward',
        id: 'scholarreward',
        accessor: d => (
          <>
            <span>
              {d.totalHPLRewardClaimed >= 0 ? new BigNumber(d.scholarHPLEarn).dividedBy(1e18).toFixed(2) : 0} HPL
            </span>
            <br />
            <span>
              {d.totalHPWRewardClaimed >= 0 ? new BigNumber(d.scholarHPWEarn).dividedBy(1e18).toFixed(2) : 0} HPW{' '}
            </span>
          </>
        ),
      },
      {
        Header: 'Transfer-out',
        id: 'transferOut',
        accessor: d => (
          <>
            <span>
              {d.totalHPLWithdraw >= 0 ? new BigNumber(d.totalHPLWithdraw).dividedBy(1e18).toFixed(2) : 0} HPL
            </span>
            <br />
            <span>
              {d.totalHPWWithdraw >= 0 ? new BigNumber(d.totalHPWWithdraw).dividedBy(1e18).toFixed(2) : 0} HPW{' '}
            </span>
          </>
        ),
      },
    ],
    [],
  )

  const fetchMasterBalance = async () => {
    try {
      if (address) {
        const response = await axios.get(`${apiURL}/users/playerInfo/${address}`)
        if (response.status === 200 && response.data) {
          const { totalHPLSpent: _totalHPLSpent, totalHPWSpent: _totalHPWSpent } = response.data
          setHPLSpent(_totalHPLSpent >= 0 ? new BigNumber(_totalHPLSpent).dividedBy(1e18).toFixed(2) : 0)
          setHPWSpent(_totalHPWSpent >= 0 ? new BigNumber(_totalHPWSpent).dividedBy(1e18).toFixed(2) : 0)
        }
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const fetchSubAccount = async () => {
    try {
      if (address) {
        const response = await axios.get(`${apiURL}/masters/listSubAccount/${address}`)
        if (response.status === 200 && response.data) {
          const list = response.data
          setListAccount(list)
          const _hplScholarRevenue = list
            .map(item => Number(item.scholarHPLEarn))
            .reduce((prev, curr) => prev + curr, 0)
          const _hpwScholarRevenue = list
            .map(item => Number(item.scholarHPWEarn))
            .reduce((prev, curr) => prev + curr, 0)
          const _hplRevenue = list.map(item => Number(item.totalHPLReward)).reduce((prev, curr) => prev + curr, 0)
          const _hpwRevenue = list.map(item => Number(item.totalHPWReward)).reduce((prev, curr) => prev + curr, 0)
          const _hplRevenueClaimed = list
            .map(item => Number(item.totalHPLRewardClaimed))
            .reduce((prev, curr) => prev + curr, 0)
          const _hpwRevenueClaimed = list
            .map(item => Number(item.totalHPWRewardClaimed))
            .reduce((prev, curr) => prev + curr, 0)
          setHPLScholarRevenue(
            _hplScholarRevenue >= 0 ? new BigNumber(_hplScholarRevenue).dividedBy(1e18).toFixed(2) : 0,
          )
          setHPWScholarRevenue(
            _hpwScholarRevenue >= 0 ? new BigNumber(_hpwScholarRevenue).dividedBy(1e18).toFixed(2) : 0,
          )
          setHPLRevenue(_hplRevenue >= 0 ? new BigNumber(_hplRevenue).dividedBy(1e18).toFixed(2) : 0)
          setHPWRevenue(_hpwRevenue >= 0 ? new BigNumber(_hpwRevenue).dividedBy(1e18).toFixed(2) : 0)
          setHPLRevenueClaimed(
            _hplRevenueClaimed >= 0 ? new BigNumber(_hplRevenueClaimed).dividedBy(1e18).toFixed(2) : 0,
          )
          setHPWRevenueClaimed(
            _hpwRevenueClaimed >= 0 ? new BigNumber(_hpwRevenueClaimed).dividedBy(1e18).toFixed(2) : 0,
          )
        }
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchSubAccount()
    fetchMasterBalance()
  }, [address, networkId])

  return (
    <Main bgImage={DashboardBg}>
      <Container fluid>
        <Row className="mt-5 justify-content-center">
          <AccountCol xs={12} lg={11}>
            <SectionTitle subTitle="Dashboard">Account Manager</SectionTitle>
            <div className="mb-2">
              <span>
                Total Spent: {hplSpent} HPL + {hpwSpent} HPW
              </span>
            </div>
            <div className="mb-2">
              <span>
                Total Revenue: {hplRevenue} HPL + {hpwRevenue} HPW
              </span>
            </div>
            <div className="mb-2">
              <span>
                Total Revenue Claimed: {hplRevenueClaimed} HPL + {hpwRevenueClaimed} HPW
              </span>
            </div>
            <div className="mb-4">
              <span>
                Total Scholar Revenue: {hplScholarRevenue} HPL + {hpwScholarRevenue} HPW
              </span>
            </div>
            <AccountTable columns={columns} data={listAccount} />
          </AccountCol>
        </Row>
      </Container>
    </Main>
  )
}

export default CheckAccount
