import { ThemeProvider } from 'styled-components/macro'
import styled from 'styled-components/macro'
import Inventory from './pages/Inventory'
import Dashboard from './pages/Dashboard'
import ManagerAccount from './pages/ManagerAccount'
import ClaimedHistory from './pages/ClaimedHistory'
import SingleAccount from './pages/SingleAccount'
import History from './pages/History'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Web3ReactManager from './components/Web3ReactManager'
import PreLoader from './components/PreLoader'
import Header from './components/Header'
import ScrollTop from './components/ScrollTop'
import theme from './theme'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import './assets/css/toastify.css'
import './assets/css/style.css'
import Synchronize from './pages/Synchronize'
import CheckAccount from './pages/CheckAccount'

const Main = styled.section`
  position: relative;
`

function App(): JSX.Element {
  return (
    <Web3ReactManager>
      <ThemeProvider theme={theme}>
        <Router>
          <Header />
          <PreLoader />
          <Main>
            <Switch>
              <Route exact path="/">
                <ManagerAccount />
              </Route>
              <Route exact path="/manager">
                <ManagerAccount />
              </Route>
              <Route exact path="/manager/:slug">
                <SingleAccount />
              </Route>
              <Route exact path="/checkaccount/:slug">
                <CheckAccount />
              </Route>
              <Route exact path="/dashboard">
                <Dashboard />
              </Route>
              <Route exact path="/synchronize">
                <Synchronize />
              </Route>
              <Route exact path="/inventory">
                <Inventory />
              </Route>
              <Route exact path="/history/:slug">
                <History />
              </Route>
              <Route exact path="/claimedhistory/:slug">
                <ClaimedHistory />
              </Route>
            </Switch>
          </Main>
          <ScrollTop />
        </Router>
      </ThemeProvider>
    </Web3ReactManager>
  )
}

export default App
