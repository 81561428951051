import { useState, useRef, useEffect } from 'react'
import axios, { AxiosError } from 'axios'
import Web3 from 'web3'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../ToastMessage'
import Button from '../Button'
import Modal from '../Modal'
import Land from '../../types/Land'
import { fromWei, toWei } from '../../utils'
import {
  useActiveWeb3React,
  ApprovalState,
  useApproveCallback,
  useGameControlContract,
  useMarketPlaceContract,
  useNFTContract,
} from '../../hooks'
import config from '../../config/config.json'
import {
  LandImage,
  LandID,
  LandName,
  ButtonWrapper,
  Input,
  InvalidText,
  DepositInputGroup,
  DepositInputInfo,
  StyledInputGroup,
  FormSelect,
  ListViewNFT,
  ListViewImg,
  ListViewContent,
  InfoWapper,
  LandPrice,
  ListViewFooter,
  LandOwner,
  ButtonWrap,
  LandPriceExchange,
  LandPriceWrapper,
} from './Styled'
import BigNumber from 'bignumber.js'

interface ILandProps {
  land: Land
  player?: string
  tokenAddress?: string
  layout: string
  page: 'marketplace' | 'inventory' | 'playtoearn' | 'manager'
  rateBNB?: number
  rateHPL?: number
  isApproving?: boolean
  needApprove?: boolean
  onApprove?: () => void
  onRefresh: () => void
}

function LandListView(props: ILandProps): JSX.Element {
  const { land, player, tokenAddress, layout, rateBNB, rateHPL, isApproving, needApprove, page, onApprove, onRefresh } =
    props
  // @ts-ignore
  const imagesDir = require.context(`../../assets/images/`, true)
  const sellerEllipsis = `${land.seller?.substring(0, 6)}...${land.seller?.substring(land.seller?.length - 4)}`

  const refSell = useRef()
  const refChangePrice = useRef()
  const refGift = useRef()

  const [showSellModal, setShowSellModal] = useState(false)
  const [isApprovingBUSD, setApprovingBUSD] = useState(false)
  const [isApprovingHPL, setApprovingHPL] = useState(false)
  const [showEditPriceModal, setShowEditPriceModal] = useState(false)
  const [showGiftModal, setShowGiftModal] = useState(false)
  const [isSelling, setSelling] = useState(false)
  const [isEditing, setEditing] = useState(false)
  const [isGifting, setGifting] = useState(false)
  const [isWithrawing, setWithdrawing] = useState(false)
  const [isCanceling, setCanceling] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [price, setPrice] = useState(0)
  const [editPrice, setEditPrice] = useState<any>(0)
  const [receiverAddress, setReceiverAddress] = useState('')
  const [isAddressValid, setAddressValid] = useState(false)

  const { account, chainId, library } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]
  const web3 = new Web3(library)
  const [paymentToken, setPaymentToken] = useState(config.payments[networkId].BUSD)
  const marketplaceContract = useMarketPlaceContract(config.contracts[networkId].Market)
  const gameContract = useGameControlContract(config.contracts[networkId].GameControl)
  const nftContract = useNFTContract(config.contracts[networkId].LandNFT)

  const [approvalBUSD, approveCallbackBUSD] = useApproveCallback(
    config.payments[networkId].BUSD,
    config.contracts[networkId].Market,
  )

  const [approvalHPL, approveCallbackHPL] = useApproveCallback(
    config.payments[networkId].HPL,
    config.contracts[networkId].Market,
  )

  const onApproveBUSD = async () => {
    try {
      setApprovingBUSD(true)
      const receipt = await approveCallbackBUSD()

      if (receipt && marketplaceContract) {
        const _isApprovedForAll = await marketplaceContract.methods
          .isApprovedForAll(config.payments[networkId].BUSD, config.contracts[networkId].Market)
          .call()

        if (_isApprovedForAll) {
          toast.success(<ToastMessage color="success" bodyText="Now you can buy lands using BUSD" />, {
            toastId: 'onApprove',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
    } catch (error: any) {
      // we only lande if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve. Please try again." />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApprovingBUSD(false)
    }
  }

  const onApproveHPL = async () => {
    try {
      setApprovingHPL(true)
      const receipt = await approveCallbackHPL()

      if (receipt && marketplaceContract) {
        const _isApprovedForAll = await marketplaceContract.methods
          .isApprovedForAll(config.payments[networkId].HPL, config.contracts[networkId].Market)
          .call()

        if (_isApprovedForAll) {
          toast.success(<ToastMessage color="success" bodyText="Now you can buy lands using HPL" />, {
            toastId: 'onApprove',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
    } catch (error: any) {
      // we only lande if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve. Please try again." />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApprovingHPL(false)
    }
  }

  const onPriceInputChange = e => {
    const { value } = e.currentTarget
    setPrice(Number(value))
  }

  const onEditPriceInputChange = e => {
    const { value } = e.currentTarget
    setEditPrice(Number(value))
  }

  const onReceiverInputChange = e => {
    const { value } = e.currentTarget
    const addressRegex = new RegExp(/^0x[a-fA-F0-9]{40}$/g)
    const match = addressRegex.test(value)

    if (value !== account) {
      setReceiverAddress(value)
      setAddressValid(match)
    }
  }

  const onSellLand = async () => {
    try {
      setSelling(true)
      if (marketplaceContract) {
        const receipt = await marketplaceContract.methods
          .setTokenSale(
            land.tokenAddress,
            land.tokenId,
            paymentToken,
            toWei(price).toString(10),
            '0x0000000000000000000000000000000000000000',
          )
          .send({ from: account })

        if (receipt) {
          toast.success(
            <ToastMessage
              color="success"
              bodyText={`${land.land} #${land.tokenId} has been listed on the market`}
              link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              linkText="View Transaction"
            />,
            {
              toastId: 'onSellLand',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            },
          )

          setTimeout(() => {
            onRefresh()
          }, 500)
        }
        setShowSellModal(false)
      }
      setShowSellModal(false)
    } catch (error: any) {
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not sell this land. Please try again." />, {
          toastId: 'onSellLand',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setSelling(false)
    }
  }

  const onKeyUpSellLand = e => {
    if (e.keyCode === 13) {
      onSellLand()
    }
  }

  const onBuyNFTs = async () => {
    try {
      setLoading(true)
      // @ts-ignore
      const _priceBNB = land.price >= 0 ? new BigNumber(land.price).multipliedBy(1035).dividedBy(1000).toString() : 0
      if (marketplaceContract) {
        const receipt =
          land.paymentToken === '0xffffffffffffffffffffffffffffffffffffffff'
            ? await marketplaceContract.methods.buyNFT(land.saleId).send({ value: _priceBNB, from: account })
            : await marketplaceContract.methods.buyNFT(land.saleId).send({ from: account })

        if (receipt) {
          toast.success(
            <ToastMessage
              color="success"
              bodyText={`${land.land} #${land.tokenId} has been purchased successfully.`}
              link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              linkText="View Transaction"
            />,
            {
              toastId: 'onSellLand',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            },
          )

          setTimeout(() => {
            onRefresh()
          }, 500)
        }
      }
    } catch (error: any) {
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not buy this land. Please try again." />, {
          toastId: 'onSellLand',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onOpenChangePrice = priceChange => {
    const _priceChange = new BigNumber(priceChange).dividedBy(1e18).toString()
    setEditPrice(_priceChange)
    setShowEditPriceModal(true)
  }

  const onEditPrice = async () => {
    try {
      setEditing(true)
      if (marketplaceContract) {
        const receipt = await marketplaceContract.methods
          .updateSaleInfo(
            land.tokenAddress,
            land.saleId,
            toWei(editPrice).toString(10),
            '0x0000000000000000000000000000000000000000',
          )
          .send({ from: account })

        if (receipt) {
          toast.success(
            <ToastMessage
              color="success"
              bodyText={`${land.land} #${land.tokenId} has been listed on the market`}
              link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              linkText="View Transaction"
            />,
            {
              toastId: 'onSellLand',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            },
          )

          setTimeout(() => {
            onRefresh()
          }, 500)
        }
        setShowEditPriceModal(false)
      }
      setShowEditPriceModal(false)
    } catch (error: any) {
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not sell this land. Please try again." />, {
          toastId: 'onSellLand',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setEditing(false)
    }
  }

  const onKeyUpEditPrice = e => {
    if (e.keyCode === 13) {
      onEditPrice()
    }
  }

  const onCancelSelling = async () => {
    try {
      setCanceling(true)
      if (marketplaceContract) {
        const receipt = await marketplaceContract.methods
          .cancelTokenSale(land.tokenAddress, land.saleId)
          .send({ from: account })

        if (receipt) {
          toast.success(
            <ToastMessage
              color="success"
              bodyText={`${land.land} #${land.tokenId} has been canceled for sale.`}
              link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              linkText="View Transaction"
            />,
            {
              toastId: 'onCancelSelling',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            },
          )

          setTimeout(() => {
            onRefresh()
          }, 500)
        }
      }
    } catch (error: any) {
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not cancel sale of this land. Please try again." />, {
          toastId: 'onCancelSelling',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setCanceling(true)
    }
  }

  const onGiftLand = async () => {
    try {
      setGifting(true)
      if (nftContract) {
        const receipt = await nftContract.methods
          .safeTransferFrom(account, receiverAddress, land.tokenId)
          .send({ from: account })

        if (receipt) {
          toast.success(
            <ToastMessage
              color="success"
              bodyText={`${land.land} #${land.tokenId} has been transferred to your friend`}
              link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              linkText="View Transaction"
            />,
            {
              toastId: 'onGiftLand',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            },
          )

          setTimeout(() => {
            onRefresh()
          }, 500)
        }
      }
      setShowGiftModal(false)
    } catch (error: any) {
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not transfer this land. Please try again." />, {
          toastId: 'onGiftLand',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setGifting(false)
    }
  }

  const onWithdrawNFT = async () => {
    try {
      setWithdrawing(true)

      if (account && gameContract) {
        const response = await axios.post(`${apiURL}/games/requestWithdrawNFT/${land.tokenAddress}`, {
          player: account,
          tokenIds: String(land.tokenId),
        })

        if (response.status === 200 && response.data) {
          const { nft, tokenIds, expiredTime, r, s, v } = response.data
          const receipt = await gameContract.methods
            .withdrawNFTs(nft, tokenIds, expiredTime, r, s, v)
            .send({ from: account })

          if (receipt) {
            onRefresh()

            toast.success(
              <ToastMessage
                color="success"
                bodyText="Unlock NFT successfully"
                link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
                linkText="View Transaction"
              />,
              {
                toastId: 'onUnlockNFT',
                position: 'bottom-right',
                autoClose: 50000,
                hideProgressBar: true,
                transition: Zoom,
              },
            )
          }
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const msg = _e.response?.data.errors
        toast.error(<ToastMessage color="error" bodyText={msg ? msg.toUpperCase() : 'Could not unlock this NFT.'} />, {
          toastId: 'onUnlockNFT',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      } else {
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          toast.error(<ToastMessage color="error" bodyText="Could not unlock this NFT." />, {
            toastId: 'onUnlockNFT',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
        console.error(error)
      }
    } finally {
      setWithdrawing(false)
    }
  }

  const onRevokeLandFromSubAccount = async () => {
    try {
      setWithdrawing(true)

      const expiry = (Date.now() / 1000 + 120).toFixed(0)
      const tokenIds = String(land.tokenId)
      const msg = `${account}-${player}-${tokenAddress}-${tokenIds}-${expiry}`
      const hashedMsg = web3.utils.sha3(msg.toLowerCase())
      if (hashedMsg && account) {
        const signature = await web3.eth.personal.sign(hashedMsg, account, '')
        const response = await axios.post(`${apiURL}/masters/revokeNFTFromSubAccount`, {
          master: account,
          player,
          tokenAddress,
          tokenIds,
          expiry,
          signature,
        })
        if (response.status === 200 && response.data) {
          onRefresh()

          toast.success(<ToastMessage color="success" bodyText="Revoke NFT successfully" />, {
            toastId: 'onUnlockNFT',
            position: 'bottom-right',
            autoClose: 50000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const msg = _e.response?.data.errors
        toast.error(<ToastMessage color="error" bodyText={msg ? msg.toUpperCase() : 'Could not unlock this NFT.'} />, {
          toastId: 'onUnlockNFT',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      } else {
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          toast.error(<ToastMessage color="error" bodyText="Could not unlock this NFT." />, {
            toastId: 'onUnlockNFT',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
        console.error(error)
      }
    } finally {
      setWithdrawing(false)
    }
  }

  const onKeyUpGiftLand = e => {
    if (e.keyCode === 13 && isAddressValid) {
      onGiftLand()
    }
  }

  const onPaymentTokenChange = e => {
    const { value } = e.currentTarget
    setPaymentToken(value)
  }

  const priceNFT = priceInput => {
    if (priceInput > 0) {
      return fromWei(priceInput).toNumber()
    }
  }

  const tokenPaymentFn = tokenPayment => {
    switch (tokenPayment) {
      case config.payments[networkId].BNB:
        return 'BNB'
      case config.payments[networkId].HPL:
        return 'HPL'
      case config.payments[networkId].BUSD:
        return 'BUSD'
      default:
        return null
    }
  }

  useEffect(() => {
    if (refSell.current) {
      // @ts-ignore
      refSell.current.focus()
    }

    if (refGift.current) {
      // @ts-ignore
      refGift.current.focus()
    }
    if (refChangePrice.current) {
      // @ts-ignore
      refChangePrice.current.focus()
    }
  })

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <ListViewNFT layout={layout}>
        <ListViewImg layout={layout}>
          <LandImage src={imagesDir(`./lands/${land.land}.png`).default} alt={land.land} />
          <InfoWapper>
            <LandName theme={land.land}>{land.land}</LandName>
            <LandID theme={land.land}>{`#${land.tokenId}`}</LandID>
          </InfoWapper>
        </ListViewImg>
        <ListViewContent layout={layout}>
          {page === 'manager' && (
            <ButtonWrapper>
              <Button
                color="secondary"
                disabled={isWithrawing}
                loading={isWithrawing}
                onClick={onRevokeLandFromSubAccount}
              >
                Revoke Land
              </Button>
            </ButtonWrapper>
          )}
          {page === 'marketplace' && (
            <ListViewFooter layout={layout}>
              <div>
                {land.price && (
                  <LandPriceWrapper>
                    <LandPrice>
                      {priceNFT(land.price)} {tokenPaymentFn(land.paymentToken?.toLowerCase())}
                    </LandPrice>
                    {land.paymentToken?.toLowerCase() === config.payments[networkId].HPL && (
                      //@ts-ignore
                      <LandPriceExchange>(~ ${Number(priceNFT(land.price) * rateHPL).toFixed(1)})</LandPriceExchange>
                    )}
                    {land.paymentToken?.toLowerCase() === config.payments[networkId].BNB && (
                      //@ts-ignore
                      <LandPriceExchange>(~ ${Number(priceNFT(land.price) * rateBNB).toFixed(1)})</LandPriceExchange>
                    )}
                  </LandPriceWrapper>
                )}
                {land.seller && (
                  <LandOwner
                    href={`${config.explorerURL[networkId]}/address/${land.seller}`}
                    target="_blank"
                    rel="nofollow"
                    style={{ display: 'inline-block', marginBottom: '8px' }}
                  >
                    Farmer: {sellerEllipsis}
                  </LandOwner>
                )}
              </div>
              <div>
                {land.paymentToken?.toLowerCase() === config.payments[networkId].BUSD && (
                  <>
                    {approvalBUSD !== ApprovalState.APPROVED ? (
                      <Button
                        color="primary"
                        disabled={isApprovingBUSD}
                        loading={isApprovingBUSD}
                        onClick={onApproveBUSD}
                      >
                        Approve BUSD
                      </Button>
                    ) : (
                      <Button color="primary" disabled={isLoading} loading={isLoading} onClick={onBuyNFTs}>
                        Buy Now
                      </Button>
                    )}
                  </>
                )}
                {land.paymentToken?.toLowerCase() === config.payments[networkId].HPL && (
                  <>
                    {approvalHPL !== ApprovalState.APPROVED ? (
                      <Button color="primary" disabled={isApprovingHPL} loading={isApprovingHPL} onClick={onApproveHPL}>
                        Approve HPL
                      </Button>
                    ) : (
                      <Button color="primary" disabled={isLoading} loading={isLoading} onClick={onBuyNFTs}>
                        Buy Now
                      </Button>
                    )}
                  </>
                )}
                {land.paymentToken?.toLowerCase() === config.payments[networkId].BNB && (
                  <Button color="primary" disabled={isLoading} loading={isLoading} onClick={onBuyNFTs}>
                    Buy Now
                  </Button>
                )}
              </div>
            </ListViewFooter>
          )}

          {page === 'playtoearn' && (
            <>
              {needApprove ? (
                <ButtonWrapper>
                  <Button color="primary" disabled={isApproving} loading={isApproving} onClick={onApprove}>
                    <span>Approve</span>
                  </Button>
                </ButtonWrapper>
              ) : (
                <ButtonWrapper>
                  <Button disabled={isWithrawing} loading={isWithrawing} onClick={onWithdrawNFT}>
                    Unlock
                  </Button>
                </ButtonWrapper>
              )}
            </>
          )}

          {page === 'inventory' && (
            <>
              {needApprove ? (
                <ButtonWrapper>
                  <Button color="primary" disabled={isApproving} loading={isApproving} onClick={onApprove}>
                    <span>Approve</span>
                  </Button>
                </ButtonWrapper>
              ) : (
                <>
                  {land.seller === account?.toLowerCase() && config.market && (
                    <ButtonWrapper>
                      <Button
                        color="primary"
                        onClick={() => onOpenChangePrice(land.price)}
                        disabled={isEditing}
                        loading={isEditing}
                      >
                        Change Price
                      </Button>
                      <Button color="secondary" onClick={onCancelSelling} disabled={isCanceling} loading={isCanceling}>
                        Cancel Selling
                      </Button>
                    </ButtonWrapper>
                  )}
                  {!land.gameDepositor && land.seller !== account?.toLowerCase() && config.market && (
                    <ButtonWrapper>
                      <Button
                        color="primary"
                        onClick={() => setShowSellModal(true)}
                        disabled={isSelling}
                        loading={isSelling}
                      >
                        Sell
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => setShowGiftModal(true)}
                        disabled={isGifting}
                        loading={isGifting}
                      >
                        Transfer
                      </Button>
                    </ButtonWrapper>
                  )}
                </>
              )}
            </>
          )}
        </ListViewContent>
      </ListViewNFT>
      {page === 'inventory' && (
        <>
          {/* Modal Transfer NFTs */}
          <Modal
            show={showGiftModal}
            onHide={() => setShowGiftModal(false)}
            title={
              <>
                Transfer {land.land} <span>#{land.tokenId}</span>
              </>
            }
          >
            <p>Enter receiver's BSC wallet address to continue:</p>
            <Input
              ref={refGift}
              placeholder="Receiver's BSC wallet address"
              onChange={e => onReceiverInputChange(e)}
              onKeyUp={e => onKeyUpGiftLand(e)}
            />
            {!isAddressValid && <InvalidText>Invalid address</InvalidText>}
            <ButtonWrap>
              <Button color="primary" onClick={onGiftLand} disabled={isGifting} loading={isGifting}>
                Transfer Your Land
              </Button>
            </ButtonWrap>
          </Modal>
          {/* Modal Sell NFTs */}
          <Modal
            show={showSellModal}
            onHide={() => setShowSellModal(false)}
            title={
              <>
                Sell {land.land} <span>#{land.tokenId}</span>
              </>
            }
          >
            <p>
              Your land will be listed on the market with this price.
              <br />
              In order to get it back, you'll have to cancel the sale.
            </p>
            <DepositInputGroup>
              <DepositInputInfo>
                <div className="d-flex justify-content-between w-100">
                  <h6>Input</h6>
                </div>
              </DepositInputInfo>
              <StyledInputGroup>
                <Input
                  ref={refSell}
                  type="number"
                  placeholder="0"
                  onChange={e => onPriceInputChange(e)}
                  onKeyUp={e => onKeyUpSellLand(e)}
                  borderwidth="0"
                  paddinginput="0"
                />
                <FormSelect aria-label="Token Payment" value={paymentToken} onChange={e => onPaymentTokenChange(e)}>
                  <option value={config.payments[networkId].BUSD}>BUSD</option>
                  <option value={config.payments[networkId].HPL}>HPL</option>
                  <option value={config.payments[networkId].BNB}>BNB</option>
                </FormSelect>
              </StyledInputGroup>
            </DepositInputGroup>
            <ButtonWrap>
              <Button color="primary" onClick={onSellLand} disabled={isSelling} loading={isSelling}>
                Sell
              </Button>
            </ButtonWrap>
          </Modal>
          {/* Modal Change Price NFTs */}
          <Modal
            show={showEditPriceModal}
            onHide={() => setShowEditPriceModal(false)}
            title={
              <>
                Change price {land.land?.toUpperCase()} <span>#{land.tokenId}</span>
              </>
            }
          >
            <DepositInputGroup>
              <DepositInputInfo>
                <div className="d-flex justify-content-between w-100">
                  <h6>Input</h6>
                </div>
              </DepositInputInfo>
              <StyledInputGroup>
                <Input
                  ref={refChangePrice}
                  type="number"
                  placeholder="0"
                  value={editPrice}
                  onChange={e => onEditPriceInputChange(e)}
                  onKeyUp={e => onKeyUpEditPrice(e)}
                  borderwidth="0"
                  paddinginput="0"
                />
                <FormSelect
                  aria-label="Token Payment"
                  value={land.paymentToken}
                  disabled
                  onChange={e => onPaymentTokenChange(e)}
                >
                  <option value={config.payments[networkId].BUSD}>BUSD</option>
                  <option value={config.payments[networkId].HPL}>HPL</option>
                  <option value={config.payments[networkId].BNB}>BNB</option>
                </FormSelect>
              </StyledInputGroup>
            </DepositInputGroup>
            <ButtonWrap>
              <Button color="primary" onClick={onEditPrice} disabled={isEditing} loading={isEditing}>
                Change Price
              </Button>
            </ButtonWrap>
          </Modal>
        </>
      )}
    </>
  )
}

export default LandListView
