export const black = '#161417'
export const orange = {
  200: '#FFF9AC',
  300: '#D75212',
  400: '#ff4800',
  500: '#FFE843',
}
export const purple = {
  300: '#291b35',
  400: '#1f1528',
}
