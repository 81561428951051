import styled from 'styled-components/macro'

export const SelectBoxWrapper = styled.div`
  position: relative;
  cursor: pointer;

  ul {
    list-style: none;
    border: none;
    background-color: rgba(233, 195, 146, 0.3);
    position: absolute;
    backdrop-filter: blur(12px);
    z-index: 999;
  }

  li {
    padding: 5px 15px;
    text-align: left;
    font-weight: 500;
    transition: all 0.5s ease;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    color: #5a2b00;

    &:hover {
      color: #ff931d;
    }

    &.active {
      color: #ff931d;
      background-color: #5a2b00;
    }
  }
`
export const SelectBoxText = styled.span`
  display: inline-block;
  padding: 5px 15px;
  border: 1px dashed #5a2b00;
  background-color: rgba(233, 195, 146, 0.3);
  letter-spacing: 0.05em;
  transition: all 0.5s ease;
  font-weight: 500;
  color: #731c00;
  border-radius: 5px;

  svg {
    margin-left: 1rem;
  }
`
