import { useState } from 'react'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import { useActiveWeb3React } from '../../hooks'
import config from '../../config/config.json'
import { Form } from 'react-bootstrap'
import {
  ButtonWrapper,
  FormInput,
  FormSelect,
  FormLabel,
  SecondFormLabel,
  LabelTitle,
  FormTextDanger,
  PercentText,
} from './Styled'
import PasswordStrengthBar from 'react-password-strength-bar'
import Web3 from 'web3'
import axios, { AxiosError } from 'axios'

interface IDepositTokenModalProps {
  show: boolean
  onHide: () => void
  onRefresh: () => void
}

function CreateAccountModal(props: IDepositTokenModalProps): JSX.Element {
  const { show, onHide, onRefresh } = props

  const { account, chainId, library } = useActiveWeb3React()
  const web3 = new Web3(library)
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]

  const [isRegistering, setRegistering] = useState(false)
  const [username, setUsername] = useState('')
  const [location, setLocation] = useState('asia')
  const [wallet, setWallet] = useState('')
  const [percent, setPercent] = useState('')
  const [password, setPassword] = useState('')
  const [passwordScore, setPasswordScore] = useState(1)
  const [isUsernameValid, setUsernameValid] = useState(false)
  const [isPasswordMatched, setPasswordMatched] = useState(false)

  const onUsernameChange = e => {
    const { value } = e.currentTarget
    const usernameRegex = new RegExp(/^[a-z0-9]{6,20}$/g)
    setUsername(value)

    const match = usernameRegex.test(value)
    setUsernameValid(match)
  }

  const onLocationChange = e => {
    const { value } = e.currentTarget
    setLocation(value)
  }

  const onWalletChange = e => {
    const { value } = e.currentTarget
    setWallet(value)
  }

  const onPercentChange = e => {
    const { value } = e.currentTarget
    setPercent(value)
  }

  const onPasswordChange = e => {
    const { value } = e.currentTarget
    setPassword(value)
  }

  const onConfirmPasswordChange = e => {
    const { value } = e.currentTarget
    setPasswordMatched(value === password)
  }

  const onChangeScore = score => {
    setPasswordScore(score)
  }

  const closeModal = () => {
    setUsername('')
    setLocation('asia')
    setWallet('')
    setPercent('')
    setPassword('')
    onHide()
  }

  const onRegister = async () => {
    try {
      setRegistering(true)

      if (account) {
        const hashedPassword = password
        const expiry = (Date.now() / 100 + 15).toFixed(0)

        if (hashedPassword) {
          const hashedMsg = web3.utils.sha3(
            `${account.toLowerCase()}-${username.toLowerCase()}-${location}-${expiry}-${wallet.toLowerCase()}-${percent}`,
          )

          if (hashedMsg) {
            const signature = await web3.eth.personal.sign(hashedMsg, account.toLowerCase(), '')

            const response = await axios.post(`${apiURL}/masters/createSubAccount`, {
              username,
              password: hashedPassword,
              location,
              player: wallet,
              percent,
              expiry,
              master: account.toLowerCase(),
              signature,
            })

            if (response.status === 200 && response.data) {
              toast.success(<ToastMessage color="success" bodyText="Register success." />, {
                toastId: 'onRegister',
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: true,
                transition: Zoom,
              })
            }
            await onRefresh()
            closeModal()
          } else {
            toast.error(<ToastMessage color="error" bodyText="Could not register." />, {
              toastId: 'onRegister',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            })
            throw new Error('Encrypt message failed')
          }
        } else {
          toast.error(<ToastMessage color="error" bodyText="Could not register." />, {
            toastId: 'onRegister',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
          throw new Error('Encrypt password failed')
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const msg = _e.response?.data.errors
        toast.error(<ToastMessage color="error" bodyText={msg ? msg.toUpperCase() : 'Could not register.'} />, {
          toastId: 'onRegister',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setRegistering(false)
    }
  }

  return (
    <Modal show={show} onHide={closeModal} title="Create Scholar Account">
      <Form>
        <Form.Group controlId="formEmail">
          <FormLabel controlId="floatingFormEmail" label="Username">
            <FormInput type="text" placeholder="Username" value={username} onChange={e => onUsernameChange(e)} />
            {!isUsernameValid && (
              <FormTextDanger className="d-block mt-2 text-danger">
                The username needs to be between 6 - 20 characters without special characters, spaces, and uppercase
                letters.
              </FormTextDanger>
            )}
          </FormLabel>
        </Form.Group>
        <Form.Group controlId="formWallet">
          <FormLabel controlId="floatingFormWallet" label="Scholar's Wallet">
            <FormInput
              type="text"
              placeholder="0x0000000000000000000000000000000000000000"
              value={wallet}
              onChange={e => onWalletChange(e)}
            />
          </FormLabel>
        </Form.Group>
        <Form.Group controlId="formPercent">
          <FormLabel className="d-flex" controlId="floatingFormPercent" label="Commission">
            <FormInput type="text" placeholder="30" value={percent} onChange={e => onPercentChange(e)} />
            <PercentText>%</PercentText>
          </FormLabel>
        </Form.Group>
        <Form.Group controlId="location">
          <SecondFormLabel>
            <LabelTitle>Server</LabelTitle>
            <FormSelect aria-label="Server" onChange={e => onLocationChange(e)}>
              <option value="asia">Asia</option>
              <option value="eu">Europe</option>
            </FormSelect>
          </SecondFormLabel>
        </Form.Group>
        <Form.Group controlId="formPassword">
          <FormLabel controlId="floatingFormPassword" label="Password *">
            <FormInput
              type="password"
              className="mb-2"
              placeholder="Password"
              value={password}
              onChange={e => onPasswordChange(e)}
            />
          </FormLabel>
          <PasswordStrengthBar
            password={password}
            minLength={6}
            shortScoreWord="The password field must be at least 6 characters"
            scoreWordStyle={{ color: '#5a2b00' }}
            onChangeScore={onChangeScore}
          />
        </Form.Group>
        <Form.Group controlId="formPasswordConfirm">
          <FormLabel controlId="floatingFormPasswordConfirm" label="Confirm Password *">
            <FormInput type="password" placeholder="Confirm Password" onChange={e => onConfirmPasswordChange(e)} />
            {!isPasswordMatched && (
              <FormTextDanger className="d-block mt-2 text-danger">Passwords do not match</FormTextDanger>
            )}
          </FormLabel>
        </Form.Group>
        <ButtonWrapper>
          <Button
            disabled={isRegistering || !isUsernameValid || passwordScore < 2 || !isPasswordMatched}
            loading={isRegistering}
            onClick={onRegister}
          >
            Create account
          </Button>
        </ButtonWrapper>
      </Form>
    </Modal>
  )
}

export default CreateAccountModal
