import { useEffect, useState, useMemo } from 'react'
import axios, { AxiosError } from 'axios'
import { Container, Row } from 'react-bootstrap'
import Main from '../../components/Main'
import Button from '../../components/Button'
import { Link } from 'react-router-dom'
import ConnectModal from '../../components/AccountButton/ConnectModal'
import CreateAccountModal from './CreateAccountModal'
import DepositTokenModal from './DepositTokenModal'
import AccountTable from './AccountTable'
import GuildManagementBg from '../../assets/images/guild-management-bg.png'
import config from '../../config/config.json'
import { useActiveWeb3React, useGameControlContract } from '../../hooks'
import {
  AccountCol,
  HeaderWrapper,
  GuildTitles,
  ActionsWrapper,
  ButtonsWrapper,
  CountDownWrapper,
  CountDownText,
  TokenWithdraw,
} from './Styled'
import BigNumber from 'bignumber.js'
import { toast, Zoom } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage'
import Countdown from 'react-countdown'

BigNumber.config({ EXPONENTIAL_AT: [-100, 100] })

function HPLManagerAccount(): JSX.Element {
  const [isAccountMapped, setAccountMapped] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showConnectModal, setShowConnectModal] = useState(false)
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false)
  const [listAccount, setListAccount] = useState<any>([])
  const [addressDeposit, setAddressDeposit] = useState('')
  const [addressWithDraw, setAddressWithDraw] = useState('')
  const [userName, setUserName] = useState('')
  const [hplSpent, setHPLSpent] = useState<any>(0)
  const [hpwSpent, setHPWSpent] = useState<any>(0)
  const [hplScholarRevenue, setHPLScholarRevenue] = useState<any>(0)
  const [hpwScholarRevenue, setHPWScholarRevenue] = useState<any>(0)
  const [hplRevenue, setHPLRevenue] = useState<any>(0)
  const [hpwRevenue, setHPWRevenue] = useState<any>(0)
  const [hplRevenueClaimed, setHPLRevenueClaimed] = useState<any>(0)
  const [hpwRevenueClaimed, setHPWRevenueClaimed] = useState<any>(0)

  const [hplBalance, setHPLBalance] = useState<any>(0)
  const [hpwBalance, setHPWBalance] = useState<any>(0)
  const [hplCurrentSubAcc, setHPLCurrentSubAcc] = useState<any>(0)
  const [hpwCurrentSubAcc, setHPWCurrentSubAcc] = useState<any>(0)
  const [showDepositTokenModal, setShowDepositTokenModal] = useState(false)
  const [showWithDrawTokenModal, setShowWithDrawTokenModal] = useState(false)
  const [lastClaimed, setLastClaimed] = useState<any>(0)
  const [hplMaxWithdrawal, setHPLMaxWithdrawal] = useState<string>('0')
  const [hpwMaxWithdrawal, setHPWMaxWithdrawal] = useState<string>('0')
  console.log('hpl', hplMaxWithdrawal)
  console.log('hpw', hpwMaxWithdrawal)

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]
  const gameContract = useGameControlContract(config.contracts[networkId].GameControl)

  const onOpenDepositModal = (addr: string, username: string) => {
    setShowDepositTokenModal(true)
    setAddressDeposit(addr)
    setUserName(username)
  }

  const onHideDepositTokenModal = () => {
    setShowDepositTokenModal(false)
    setAddressDeposit('')
  }

  const onOpenWithDrawModal = (
    totalHPLDeposit,
    totalHPLSpent,
    totalHPLWithDraw,
    totalHPWDeposit,
    totalHPWSpent,
    totalHPWWithDraw,
    username,
    address,
  ) => {
    setShowWithDrawTokenModal(true)
    setHPLCurrentSubAcc(
      totalHPLDeposit >= 0
        ? new BigNumber(totalHPLDeposit)
            .minus(new BigNumber(totalHPLWithDraw))
            .minus(new BigNumber(totalHPLSpent))
            .dividedBy(1e18)
        : 0,
    )
    setHPWCurrentSubAcc(
      totalHPWDeposit >= 0
        ? new BigNumber(totalHPWDeposit)
            .minus(new BigNumber(totalHPWWithDraw))
            .minus(new BigNumber(totalHPWSpent))
            .dividedBy(1e18)
        : 0,
    )
    setAddressWithDraw(address)
    setUserName(username)
  }

  const onHideWithDrawTokenModal = () => {
    setShowWithDrawTokenModal(false)
    setAddressDeposit('')
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Username',
        id: 'username',
        accessor: d => (
          <div style={{ padding: '15px 10px' }}>
            <p style={{ marginBottom: '5px' }}>{d.username}</p>
          </div>
        ),
      },
      {
        Header: 'Wallet',
        id: 'wallet',
        accessor: d => (
          <div style={{ padding: '15px 10px' }}>
            <p style={{ marginBottom: '5px' }}>
              {d.address ? `${d.address.substring(0, 6)}...${d.address.substring(d.address.length - 4)}` : ''}
            </p>
          </div>
        ),
      },
      {
        Header: 'Commission',
        id: 'commission',
        accessor: d => <p className="text-center mb-0">{d.commission ? `${d.commission} %` : 'N/A'}</p>,
      },
      {
        Header: 'Total Deposit',
        id: 'deposit',
        accessor: d => (
          <>
            <span>{d.totalHPLDeposit >= 0 ? new BigNumber(d.totalHPLDeposit).dividedBy(1e18).toFixed(2) : 0} HPL</span>
            <br />
            <span>{d.totalHPWDeposit >= 0 ? new BigNumber(d.totalHPWDeposit).dividedBy(1e18).toFixed(2) : 0} HPW </span>
          </>
        ),
      },
      {
        Header: 'Total Spent',
        id: 'spent',
        accessor: d => (
          <>
            <span>{d.totalHPLSpent >= 0 ? new BigNumber(d.totalHPLSpent).dividedBy(1e18).toFixed(2) : 0} HPL</span>
            <br />
            <span>{d.totalHPWSpent >= 0 ? new BigNumber(d.totalHPWSpent).dividedBy(1e18).toFixed(2) : 0} HPW </span>
          </>
        ),
      },
      {
        Header: 'Total Revenue',
        id: 'revenue',
        accessor: d => (
          <>
            <span>{d.totalHPLReward >= 0 ? new BigNumber(d.totalHPLReward).dividedBy(1e18).toFixed(2) : 0} HPL</span>
            <br />
            <span>{d.totalHPWReward >= 0 ? new BigNumber(d.totalHPWReward).dividedBy(1e18).toFixed(2) : 0} HPW </span>
          </>
        ),
      },
      {
        Header: 'Total Reward/Payout',
        id: 'rewardpayout',
        accessor: d => (
          <>
            <span>
              {d.totalHPLRewardClaimed >= 0
                ? new BigNumber(d.totalHPLReward)
                    .minus(new BigNumber(d.totalHPLRewardClaimed))
                    .dividedBy(1e18)
                    .toFixed(2)
                : 0}{' '}
              HPL
            </span>
            <br />
            <span>
              {d.totalHPWRewardClaimed >= 0
                ? new BigNumber(d.totalHPWReward)
                    .minus(new BigNumber(d.totalHPWRewardClaimed))
                    .dividedBy(1e18)
                    .toFixed(2)
                : 0}{' '}
              HPW{' '}
            </span>
          </>
        ),
      },
      {
        Header: 'Action',
        id: 'action',
        accessor: d => (
          <>
            <ActionsWrapper>
              <Button color="third" fontSize="medium" icon={true}>
                <Link to={`/manager/${d.address}`}>Detail</Link>
              </Button>
              <Button color="primary" fontSize="medium" onClick={() => onOpenDepositModal(d.address, d.username)}>
                Top up
              </Button>
              <Button
                color="primary"
                fontSize="medium"
                onClick={() =>
                  onOpenWithDrawModal(
                    d.totalHPLDeposit,
                    d.totalHPLSpent,
                    d.totalHPLWithdraw,
                    d.totalHPWDeposit,
                    d.totalHPWSpent,
                    d.totalHPWWithdraw,
                    d.username,
                    d.address,
                  )
                }
              >
                Withdraw
              </Button>
            </ActionsWrapper>
          </>
        ),
      },
    ],
    [],
  )

  const fetchMasterBalance = async () => {
    try {
      if (account) {
        const response = await axios.get(`${apiURL}/users/playerInfo/${account}`)

        if (response.status === 200 && response.data) {
          const {
            isMaster: _isMaster,
            totalHPLDeposit: _totalHPLDeposit,
            totalHPWDeposit: _totalHPWDeposit,
            totalHPLSpent: _totalHPLSpent,
            totalHPWSpent: _totalHPWSpent,
            totalHPLWithdraw: _totalHPLWithdraw,
            totalHPWWithdraw: _totalHPWWithdraw,
          } = response.data
          if (_isMaster) {
            setAccountMapped(true)
          }
          setHPLSpent(_totalHPLSpent >= 0 ? new BigNumber(_totalHPLSpent).dividedBy(1e18).toFixed(2) : 0)
          setHPWSpent(_totalHPWSpent >= 0 ? new BigNumber(_totalHPWSpent).dividedBy(1e18).toFixed(2) : 0)
          setHPLBalance(
            _totalHPLDeposit >= 0
              ? new BigNumber(_totalHPLDeposit)
                  .minus(new BigNumber(_totalHPLWithdraw))
                  .minus(new BigNumber(_totalHPLSpent))
                  .dividedBy(1e18)
              : 0,
          )
          setHPWBalance(
            _totalHPWDeposit >= 0
              ? new BigNumber(_totalHPWDeposit)
                  .minus(new BigNumber(_totalHPWWithdraw))
                  .minus(new BigNumber(_totalHPWSpent))
                  .dividedBy(1e18)
              : 0,
          )
        }
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const fetchSubAccount = async () => {
    try {
      if (account) {
        const response = await axios.get(`${apiURL}/masters/listSubAccount/${account}`)
        if (response.status === 200 && response.data) {
          const list = response.data
          setListAccount(list)
          const _hplScholarRevenue = list
            .map(item => Number(item.scholarHPLEarn))
            .reduce((prev, curr) => prev + curr, 0)
          const _hpwScholarRevenue = list
            .map(item => Number(item.scholarHPWEarn))
            .reduce((prev, curr) => prev + curr, 0)
          const _hplRevenue = list.map(item => Number(item.totalHPLReward)).reduce((prev, curr) => prev + curr, 0)
          const _hpwRevenue = list.map(item => Number(item.totalHPWReward)).reduce((prev, curr) => prev + curr, 0)
          const _hplRevenueClaimed = list
            .map(item => Number(item.totalHPLRewardClaimed))
            .reduce((prev, curr) => prev + curr, 0)
          const _hpwRevenueClaimed = list
            .map(item => Number(item.totalHPWRewardClaimed))
            .reduce((prev, curr) => prev + curr, 0)
          setHPLScholarRevenue(
            _hplScholarRevenue >= 0 ? new BigNumber(_hplScholarRevenue).dividedBy(1e18).toFixed(2) : 0,
          )
          setHPWScholarRevenue(
            _hpwScholarRevenue >= 0 ? new BigNumber(_hpwScholarRevenue).dividedBy(1e18).toFixed(2) : 0,
          )
          setHPLRevenue(_hplRevenue >= 0 ? new BigNumber(_hplRevenue).dividedBy(1e18).toFixed(2) : 0)
          setHPWRevenue(_hpwRevenue >= 0 ? new BigNumber(_hpwRevenue).dividedBy(1e18).toFixed(2) : 0)
          setHPLRevenueClaimed(
            _hplRevenueClaimed >= 0 ? new BigNumber(_hplRevenueClaimed).dividedBy(1e18).toFixed(2) : 0,
          )
          setHPWRevenueClaimed(
            _hpwRevenueClaimed >= 0 ? new BigNumber(_hpwRevenueClaimed).dividedBy(1e18).toFixed(2) : 0,
          )
        }
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const fetchLastRewardClaimed = async () => {
    let _lastRewardClaimed = 0
    try {
      if (account && gameContract) {
        _lastRewardClaimed = await gameContract.methods.getUserInfo2(account).call()
        // @ts-ignore
        const { lastRewardClaimedAt } = _lastRewardClaimed
        setLastClaimed(Number(lastRewardClaimedAt) + 172800)
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const fetchMaxWithdrawalNew = async () => {
    let _maxWithdrawal = 0
    try {
      if (account && gameContract) {
        _maxWithdrawal = await gameContract.methods.getMaxWithdrawalNew(account, false, true).call()
        // @ts-ignore
        const [_hplMaxWithdrawal, _hpwMaxWithdrawal] = _maxWithdrawal
        setHPLMaxWithdrawal(_hplMaxWithdrawal > 0 ? new BigNumber(_hplMaxWithdrawal).dividedBy(1e18).toFixed(3) : '0')
        setHPWMaxWithdrawal(_hpwMaxWithdrawal > 0 ? new BigNumber(_hpwMaxWithdrawal).dividedBy(1e18).toFixed(3) : '0')
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchLastRewardClaimed()
    fetchSubAccount()
    fetchMasterBalance()
    fetchMaxWithdrawalNew()
  }, [account, networkId])

  const onRefresh = async () => {
    try {
      await fetchLastRewardClaimed()
      await fetchSubAccount()
      await fetchMasterBalance()
      await fetchMaxWithdrawalNew()
    } catch (error: any) {
      console.error(error)
    }
  }

  const onClaimReward = async () => {
    try {
      setLoading(true)
      if (account && gameContract) {
        const response = await axios.post(`${apiURL}/masters/requestWithdrawReward`, {
          master: account,
        })
        if (response.status === 200) {
          const {
            rewardsAndTime,
            scholarAddresses,
            commissions,
            scholarHPLSpents,
            scholarHPWSpents,
            scholarHPLAmounts,
            scholarHPWAmounts,
            rs,
            v,
          } = response.data
          const receipt = await gameContract.methods
            .masterDistributeRewards(
              rewardsAndTime,
              scholarAddresses,
              commissions,
              scholarHPLSpents,
              scholarHPWSpents,
              scholarHPLAmounts,
              scholarHPWAmounts,
              rs,
              v,
            )
            .send({ from: account })
          if (receipt) {
            toast.success(<ToastMessage color="success" bodyText="Claim rewards successful" />, {
              toastId: 'onClaimRewards',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            })
            await onRefresh()
          }
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const msg = _e.response?.data.errors
        toast.error(<ToastMessage color="error" bodyText={msg ? msg.toUpperCase() : ''} />, {
          toastId: 'onClaimRewards',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const renderer = ({ days, hours, minutes, completed }) => {
    if (!completed) {
      return (
        <>
          <span>Next Claim :</span>
          <CountDownText>
            {days} {days > 1 ? 'Days' : 'Day'}
          </CountDownText>
          <CountDownText>
            {hours}:{minutes}
          </CountDownText>
        </>
      )
    } else {
      return <></>
    }
  }

  // @ts-ignore
  return (
    <Main bgImage={GuildManagementBg}>
      <Container fluid>
        <HeaderWrapper>
          <GuildTitles>Scholars overview</GuildTitles>
        </HeaderWrapper>
        {account ? (
          <>
            {isAccountMapped && listAccount ? (
              <Row className="d-flex justify-content-center" style={{ padding: '0 10rem' }}>
                <AccountCol>
                  <AccountTable columns={columns} data={listAccount} />
                  <div className="text-center mt-5">
                    <ButtonsWrapper>
                      <Button color="primary" fontSize="bigger" onClick={() => setShowCreateAccountModal(true)}>
                        Create Scholar Account
                      </Button>
                      <Button
                        color="secondary"
                        fontSize="bigger"
                        onClick={onClaimReward}
                        loading={loading}
                        disabled={loading}
                      >
                        Claim
                      </Button>
                      <Button icon={true} color="third" fontSize="bigger">
                        <Link to={`/claimedhistory/${account}`}>Claim History</Link>
                      </Button>
                    </ButtonsWrapper>
                    <CreateAccountModal
                      show={showCreateAccountModal}
                      onHide={() => setShowCreateAccountModal(false)}
                      onRefresh={onRefresh}
                    />
                    <DepositTokenModal
                      type="Transfer-in"
                      username={userName}
                      address={addressDeposit}
                      hplBalance={hplBalance}
                      hpwBalance={hpwBalance}
                      show={showDepositTokenModal}
                      onHide={onHideDepositTokenModal}
                      onRefresh={onRefresh}
                    />
                    <DepositTokenModal
                      type="Transfer-out"
                      username={userName}
                      address={addressWithDraw}
                      hplBalance={hplCurrentSubAcc}
                      hpwBalance={hpwCurrentSubAcc}
                      show={showWithDrawTokenModal}
                      onHide={onHideWithDrawTokenModal}
                      onRefresh={onRefresh}
                    />
                  </div>
                  {lastClaimed ? (
                    <CountDownWrapper>
                      <Countdown date={lastClaimed * 1000} renderer={renderer} />
                    </CountDownWrapper>
                  ) : (
                    <></>
                  )}
                  {hplMaxWithdrawal && hpwMaxWithdrawal ? (
                    <div className="d-flex justify-content-center">
                      <TokenWithdraw>HPL Withdraw: {hplMaxWithdrawal}</TokenWithdraw>
                      <TokenWithdraw>HPW Withdraw: {hpwMaxWithdrawal}</TokenWithdraw>
                    </div>
                  ) : (
                    <></>
                  )}
                </AccountCol>
              </Row>
            ) : (
              <Row className="justify-content-center">
                <AccountCol xs={11} md={8} className="text-center">
                  <p className="mb-0">
                    To create sub-accounts, you need to <Link to="/dashboard">create a master account here</Link>
                  </p>
                </AccountCol>
              </Row>
            )}
          </>
        ) : (
          <Container>
            <Row className="justify-content-center">
              <AccountCol xs={11} md={8} className="text-center">
                <h5 className="mb-3">Looks like your wallet is not connected.</h5>
                <p className="mb-5">Connect your wallet to continue</p>
                <Button onClick={() => setShowConnectModal(true)}>Connect Wallet</Button>
                <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
              </AccountCol>
            </Row>
          </Container>
        )}
      </Container>
    </Main>
  )
}

export default HPLManagerAccount
