import { useEffect, useState, useRef } from 'react'
import { Form } from 'react-bootstrap'
import { useActiveWeb3React, useTokenBalance } from '../../../hooks'
import { formatNumber } from '../../../utils'
import config from '../../../config/config.json'

interface ITokenInfoProps {
  tokenName: string
  description?: string
  depositedAmount: any
}

function TokenInfo(props: ITokenInfoProps): JSX.Element {
  const { tokenName, description, depositedAmount } = props

  const [tokenBalance, setTokenBalance] = useState(0)

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const tokenAddress = tokenName === 'HPL' ? config.contracts[networkId].HPL : config.contracts[networkId].HPW
  const tokenBalanceCallback = useTokenBalance(tokenAddress, account)
  const componentMounted = useRef(true)

  const fetchData = async () => {
    const _tokenBalance = await tokenBalanceCallback()
    if (componentMounted.current) {
      setTokenBalance(_tokenBalance)
    }
  }

  useEffect(() => {
    fetchData()

    return () => {
      componentMounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, tokenBalanceCallback])

  return (
    <div>
      <h5>{tokenName}</h5>
      <hr />
      <Form.Text className="d-block mb-4">{description}</Form.Text>
      <div className="mb-3">{`Balance: ${formatNumber(tokenBalance.toFixed(3))} ${tokenName}`}</div>
      <div>{`In-game balance: ${depositedAmount ? formatNumber(depositedAmount) : '0'} ${tokenName}`}</div>
    </div>
  )
}

export default TokenInfo
