import { useEffect, useState, useRef } from 'react'
// import axios, { AxiosError } from 'axios'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import Main from '../../components/Main'
import EmptyPage from '../../components/EmptyPage'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import DepositNFTsModal from './components/DepositNFTsModal'
import LandListView from '../../components/NftItem/LandListView'
import NFT from '../../types/NFT'
import Land from '../../types/Land'
import { useActiveWeb3React } from '../../hooks'
import config from '../../config/config.json'
import {
  HeaderWrapper,
  GoBackBtn,
  GuildTitles,
  DetailWrapper,
  LandsWrapper,
  LandsContent,
  RightWrapper,
  RowTokens,
  DottedLine,
  UpdateInfoWrapper,
  EmptyText,
  TokensWrapper,
  ValueWrapper,
  TokensTitle,
  TokensText,
  TokensValue,
  ButtonWrapper,
} from './Styled'
import ChangePasswordForm from './components/ChangePasswordForm'
import GuildManagementBg from '../../assets/images/guild-management-bg.png'
import Vector from '../../assets/images/history/vector.svg'
import BigNumber from 'bignumber.js'

BigNumber.config({ EXPONENTIAL_AT: [-100, 100] })

function SingleAccount(): JSX.Element {
  const [isFetchingNFTs, setFetchingNFTs] = useState(false)
  const [isAccountMapped, setAccountMapped] = useState(false)

  const [landInGame, setLandInGame] = useState<Land[]>([])
  const [itemsInGame, setItemsInGame] = useState<NFT[]>([])
  const [username, setUsername] = useState('')
  const [commission, setCommission] = useState<number>(0)
  const [allowChangeCommission, setAllowChangeCommission] = useState(false)
  const [location, setLocation] = useState('')
  const [showDepositLandsModal, setShowDepositLandsModal] = useState(false)
  const [showDepositItemsModal, setShowDepositItemsModal] = useState(false)
  const [hplBalance, setHPLBalance] = useState<any>(0)
  const [hpwBalance, setHPWBalance] = useState<any>(0)
  const [hplRevenueClaimed, setHPLRevenueClaimed] = useState<any>(0)
  const [hpwRevenueClaimed, setHPWRevenueClaimed] = useState<any>(0)

  const { account, chainId } = useActiveWeb3React()
  // @ts-ignore
  const { slug } = useParams()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]
  const address = slug
  const componentMounted = useRef(true)

  const fetchSubAccountInfo = async () => {
    try {
      setFetchingNFTs(true)
      if (address) {
        const response = await axios.get(`${apiURL}/masters/subAccount/${address}`)
        if (response.status === 200 && response.data) {
          setAccountMapped(true)
          const { username: _username, location: _location, commission: _commission } = response.data
          setUsername(_username)
          setLocation(_location)
          setCommission(_commission)
        }
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setFetchingNFTs(false)
    }
  }

  const fetchMasterBalance = async () => {
    try {
      if (account) {
        const response = await axios.get(`${apiURL}/users/playerInfo/${account}`)
        if (response.status === 200 && response.data) {
          const { isMaster: _isMaster, allowChangeCommission: _allowChangeCommission } = response.data
          if (_isMaster) {
            setAllowChangeCommission(_allowChangeCommission)
          }
        }
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const fetchSubAccountNFTs = async () => {
    try {
      setFetchingNFTs(true)
      const response = await axios.get(`${apiURL}/masters/listNFT/${address}`)
      if (response.status === 200 && response.data) {
        const tokensInGame = response.data.tokens
        const _landsInGame = tokensInGame.filter(token => token.tokenType === 'land')
        const _itemsInGame = tokensInGame.filter(token => token.tokenType === 'item')

        if (_landsInGame && _itemsInGame) {
          setLandInGame(_landsInGame)
          setItemsInGame(_itemsInGame)
        }
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setFetchingNFTs(false)
    }
  }

  const fetchSubAccountTokens = async () => {
    try {
      const response = await axios.get(`${apiURL}/masters/listSubAccount/${account}`)
      if (response.status === 200 && response.data) {
        const data = response.data
        console.log('aaaaaa', data)
        const _hplBalance = data.map(item => Number(item.totalHPLDeposit)).reduce((prev, curr) => prev + curr, 0)
        const _hpwBalance = data.map(item => Number(item.totalHPWDeposit)).reduce((prev, curr) => prev + curr, 0)
        setHPLBalance(_hplBalance >= 0 ? new BigNumber(_hplBalance).dividedBy(1e18).toFixed(2) : 0)
        setHPWBalance(_hpwBalance >= 0 ? new BigNumber(_hpwBalance).dividedBy(1e18).toFixed(2) : 0)
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const onRefresh = async () => {
    try {
      await fetchSubAccountInfo()
      await fetchMasterBalance()
      await fetchSubAccountNFTs()
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchSubAccountInfo()
    fetchMasterBalance()
    fetchSubAccountNFTs()
    fetchSubAccountTokens()
    return () => {
      componentMounted.current = false
    }
  }, [address])

  const onOpenDepositNFTsModal = (type: string) => {
    if (type === 'land') {
      setShowDepositLandsModal(true)
    } else {
      setShowDepositItemsModal(true)
    }
  }

  return (
    <Main bgImage={GuildManagementBg}>
      <Container fluid>
        <HeaderWrapper>
          <GoBackBtn>
            <Link to="/manager">
              <img src={Vector} />
            </Link>
          </GoBackBtn>
          <GuildTitles>{username}</GuildTitles>
        </HeaderWrapper>
        {isAccountMapped ? (
          <>
            {!isFetchingNFTs ? (
              <>
                <Row className="justify-content-center">
                  <DetailWrapper>
                    <LandsWrapper style={{ width: landInGame.length > 2 ? '40%' : 'auto' }}>
                      <LandsContent>
                        {landInGame.length > 0 ? (
                          <>
                            {landInGame.map(land => {
                              return (
                                <Col key={land.tokenId} md={landInGame.length <= 1 ? '12' : '6'}>
                                  <LandListView
                                    player={address}
                                    tokenAddress={config.contracts[networkId].LandNFT}
                                    layout="grid"
                                    page="manager"
                                    land={land}
                                    onRefresh={onRefresh}
                                  />
                                </Col>
                              )
                            })}
                          </>
                        ) : (
                          <EmptyText>No lands synchronized with the game.</EmptyText>
                        )}
                      </LandsContent>
                      <div className="text-center">
                        <EmptyText style={{ padding: '0' }}>Lands need to be assign to Scholar Account</EmptyText>
                        <Button onClick={() => onOpenDepositNFTsModal('land')}>Assign</Button>
                        <Button color="third" icon={true}>
                          <Link to={`/history/${address}`}>View History</Link>
                        </Button>
                        <DepositNFTsModal
                          player={address}
                          tokenAddress={config.contracts[networkId].LandNFT}
                          show={showDepositLandsModal}
                          onHide={() => setShowDepositLandsModal(false)}
                          onRefresh={onRefresh}
                          type="land"
                        />
                      </div>
                    </LandsWrapper>
                    <DottedLine
                      style={{
                        margin: `${landInGame.length <= 1 ? '4rem 6rem 10rem 5rem' : '4rem 4rem 10rem 3rem'}`,
                        border: '1px dashed #5a2b00',
                        opacity: '0.3',
                      }}
                    />
                    <RightWrapper>
                      <RowTokens>
                        <TokensWrapper lg={6} style={{ marginRight: '15px' }}>
                          <TokensTitle>In-game Balance</TokensTitle>
                          <hr />
                          <div className="d-flex">
                            <ValueWrapper>
                              <TokensText>HPW</TokensText>
                              <TokensValue>{hplBalance}</TokensValue>
                            </ValueWrapper>
                            <DottedLine />
                            <ValueWrapper>
                              <TokensText>HPL</TokensText>
                              <TokensValue>{hpwBalance}</TokensValue>
                            </ValueWrapper>
                          </div>
                        </TokensWrapper>
                        <TokensWrapper lg={6}>
                          <TokensTitle>Unclaimed Revenue</TokensTitle>
                          <hr />
                          <div className="d-flex">
                            <ValueWrapper>
                              <TokensText>HPW</TokensText>
                              <TokensValue>1000</TokensValue>
                            </ValueWrapper>
                            <DottedLine />
                            <ValueWrapper>
                              <TokensText>HPL</TokensText>
                              <TokensValue>1000</TokensValue>
                            </ValueWrapper>
                          </div>
                        </TokensWrapper>
                      </RowTokens>
                      <RowTokens>
                        <TokensWrapper>
                          <TokensTitle>Next Reward Distribution</TokensTitle>
                          <hr />
                          <div className="d-flex">
                            <ValueWrapper>
                              <TokensText>Time</TokensText>
                              <TokensValue>04/11/2022</TokensValue>
                            </ValueWrapper>
                            <DottedLine />
                            <ValueWrapper>
                              <TokensText>HPW</TokensText>
                              <TokensValue>1000</TokensValue>
                            </ValueWrapper>
                            <DottedLine />
                            <ValueWrapper>
                              <TokensText>HPL</TokensText>
                              <TokensValue>1000</TokensValue>
                            </ValueWrapper>
                          </div>
                        </TokensWrapper>
                      </RowTokens>
                      <UpdateInfoWrapper>
                        <ChangePasswordForm
                          username={username}
                          location={location}
                          wallet={address}
                          commission={commission}
                          allowChangeCommission={allowChangeCommission}
                        />
                      </UpdateInfoWrapper>
                    </RightWrapper>
                  </DetailWrapper>
                </Row>
              </>
            ) : (
              <Loading />
            )}
          </>
        ) : (
          <EmptyPage>
            <p>
              To play the game, you need to <Link to="/dashboard">create an account here</Link>
            </p>
          </EmptyPage>
        )}
      </Container>
    </Main>
  )
}

export default SingleAccount
