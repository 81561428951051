import styled, { css } from 'styled-components/macro'
import { InputGroup, Form, FormControl } from 'react-bootstrap'
import InfoBg from '../../assets/images/info-wrapper-bg.png'

export const LandImage = styled.img`
  width: 100%;
`
export const ButtonWrapper = styled.div`
  button {
    width: 100%;
    height: auto;
  }
  button + button {
    margin-top: 0.5rem;
  }
`
export const ButtonWrap = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
  }
`
export const Input = styled(FormControl)`
  box-shadow: none !important;
  border-radius: 4px;
  border: ${props => props.borderwidth || '2px'} solid rgba(255, 255, 255, 0.3);
  background-color: transparent !important;
  color: #fff !important;
  font-size: 20px;
  padding: ${props => props.paddinginput || '.375rem .75rem'};

  &:focus,
  &:active {
    border-color: #fff;
  }

  &:placeholder-shown + p {
    display: none;
  }
`
export const InvalidText = styled.p`
  margin-top: 1rem;
  font-size: 12px;
  font-weight: 400;
`
export const FormSelect = styled(Form.Select)`
  max-width: 20%;
  background: transparent;
  color: #fff;
  box-shadow: none !important;
  border-radius: 4px !important;
  border: 1px solid #fff;
  font-family: sans-serif;
  padding: 0.375rem 0.75rem;
  &:focus,
  &:active {
    border-color: ${props => props.theme.color.orange[500]};
  }
  &:disabled {
    background: transparent;
    opacity: 0.5;
  }
`
export const DepositInputGroup = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: 20px;
  background: transparent;
  z-index: 1;
  border: 1px solid #fff;
`
export const DepositInputInfo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0;
`
export const StyledInputGroup = styled(InputGroup)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
`
// List View
export const ListViewNFT = styled.div<{ layout: string }>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-flow: row nowrap;
  ${props =>
    props.layout === 'grid' &&
    `
    flex-direction: column;
    flex-flow: column nowrap;
  `}
  align-items: center;
  margin-bottom: 30px;
  border-radius: 15px;
  color: #fff;
`
export const ListViewImg = styled.div<{ layout: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 155px;
  min-height: 195px;
  border-radius: 10px;
  @media (min-width: 1200px) {
    min-width: 210px;
  }
  & > img {
    //height: 280px;
    width: auto;
  }
  ${props =>
    props.layout === 'grid' &&
    `
    width: 100%;
    min-height: 280px;
    margin-bottom: 15px;
    > img {
      max-width: 240px;
      max-height: 274px;
    }
  `}
`
export const TagStyle = css`
  width: 96px;
  height: 26px;
  padding: 0 10px;
  background: #d2cec3;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  border-radius: 0 5px 5px 0;
  text-align: center;
`
export const Ribbon = styled.span`
  position: absolute;
  right: 0;
  top: 35px;
  display: inline-block;
  ${TagStyle};
  width: 115px;
  border-radius: 5px 0 0 5px;
  background-color: #ffc11b;
  color: #982f34;
`
export const ListViewContent = styled.div<{ layout: string }>`
  padding: 0;
  ${props => props.layout === 'list' && 'padding: 0 0 0 20px;'}
`
export const ListViewDescription = styled.ul<{ layout: string }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  list-style: none;
  margin-bottom: 0.75rem;
  > li:nth-child(odd) {
    flex: 0 0 45%;
  }
  > li:nth-child(even) {
    flex: 0 0 55%;
  }
  ${props =>
    props.layout === 'list' &&
    `
    > li:nth-child(odd),
    > li:nth-child(even) {
      flex: 0 0 80%;
    }
    @media (min-width: 1200px) {
      > li:nth-child(odd) {
        flex: 0 0 40%;
      }
      > li:nth-child(even) {
        flex: 0 0 60%;
      }
    }
    `}
`
export const ListViewDescriptionItem = styled.li`
  display: flex;
  align-items: center;
  width: auto;
`
export const InfoWapper = styled.div`
  position: absolute;
  background-image: url(${InfoBg});
  background-size: 160px 53px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  bottom: 0;
  right: 5px;
  width: fit-content;
`
export const LandID = styled.span`
  color: #ff931f;
  font-size: 18px;
`
export const LandName = styled.h4`
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  margin-right: 15px;
  margin-bottom: 0;
  ${props => props.theme === 'valley' && 'color: #61ff69;'}
  ${props => props.theme === 'woodland' && 'color: #e8cd0c;'}
  ${props => props.theme === 'meadow' && 'color: #cd2ae8;'}
  ${props => props.theme === 'euphoria' && 'color: #ff6961;'}
`
export const ListViewFooter = styled.div<{ layout: string }>`
  display: flex;
  flex-direction: column;
  flex-flow: column nowrap;
  justify-content: space-between;
  button {
    width: 100%;
  }
`
export const LandPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row nowrap;
`
export const LandPrice = styled.p`
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.1;
  font-weight: 700;
  color: #fff;
  text-align: left;
`
export const LandPriceExchange = styled.span`
  margin-left: 8px;
  font-size: 16px;
  line-height: 1.1;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  text-align: right;
`
export const LandOwner = styled.a`
  display: inline-block;
  margin-bottom: 10px;
  font-size: 14px;
  color: #ffc11b;
  &:hover {
    text-decoration: underline;
  }
`
export const AttributeTitle = styled.span`
  font-size: 15px;
  color: #fff;
  span {
    font-weight: 500;
  }
`
