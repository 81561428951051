import { useCallback, useEffect, useRef, useState } from 'react'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../../components/ToastMessage'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import { BalanceText, DepositWrapper, DepositInputGroup, DepositInputInfo, Input, StyledInputGroup } from '../Styled'
import { useTokenBalance, useActiveWeb3React, useGameControlContract } from '../../../hooks'
import { formatNumber, toWei } from '../../../utils'
import config from '../../../config/config.json'

interface IDepositTokenModalProps {
  show: boolean
  onHide: () => void
  onRefresh: () => void
}

function DepositTokenModal(props: IDepositTokenModalProps): JSX.Element {
  const { show, onHide, onRefresh } = props
  const [isLoading, setLoading] = useState(false)
  const [hplAmount, setHPLAmount] = useState(0)
  const [hpwAmount, setHPWAmount] = useState(0)
  const [strHPLAmount, setStrHPLAmount] = useState('0')
  const [strHPWAmount, setStrHPWAmount] = useState('0')
  const [hplBalance, setHPLBalance] = useState(0)
  const [hpwBalance, setHPWBalance] = useState(0)

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const hplBalanceCallback = useTokenBalance(config.contracts[networkId].HPL, account)
  const hpwBalanceCallback = useTokenBalance(config.contracts[networkId].HPW, account)
  const gameContract = useGameControlContract(config.contracts[networkId].GameControl)

  const ref = useRef()
  const componentMounted = useRef(true)

  useEffect(() => {
    const fetchData = async () => {
      const _hplBalance = await hplBalanceCallback()
      const _hpwBalance = await hpwBalanceCallback()

      if (componentMounted.current) {
        setHPLBalance(_hplBalance)
        setHPWBalance(_hpwBalance)
      }
    }

    fetchData()

    if (ref.current) {
      // @ts-ignore
      ref.current.focus()
    }

    return () => {
      componentMounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, hplBalanceCallback, hpwBalanceCallback])

  const closeModal = () => {
    setHPLAmount(0)
    setStrHPLAmount('0')
    setHPWAmount(0)
    setStrHPWAmount('0')
    onHide()
  }

  const onDepositTokens = async () => {
    try {
      setLoading(true)

      if (gameContract) {
        const receipt = await gameContract.methods
          .depositTokensToPlay(toWei(hplAmount).toString(10), toWei(hpwAmount).toString(10))
          .send({ from: account })

        if (receipt) {
          toast.success(
            <ToastMessage
              color="success"
              bodyText="Deposit successful"
              link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              linkText="View Transaction"
            />,
            {
              toastId: 'onDepositTokens',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            },
          )
          await onRefresh()
          closeModal()
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not synchronized. Please try again." />, {
          toastId: 'onDepositTokens',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onHPLAmountChange = (e: any) => {
    const { value } = e.currentTarget
    setStrHPLAmount(value)
    setHPLAmount(parseInt(value, 10) || 0)
  }

  const onHPWAmountChange = (e: any) => {
    const { value } = e.currentTarget
    setStrHPWAmount(value)
    setHPWAmount(parseInt(value, 10) || 0)
  }

  const onHPLBlur = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    e => {
      setStrHPLAmount(hplAmount.toString())
    },
    [hplAmount],
  )

  const onHPWBlur = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    e => {
      setStrHPWAmount(hpwAmount.toString())
    },
    [hpwAmount],
  )

  const onFocus = (e: any) => {
    e.target.select()
  }

  const onMaHPW = () => {
    setHPLAmount(hplBalance)
    setStrHPLAmount(hplBalance.toString())
  }

  const onMaxHPW = () => {
    setHPWAmount(hpwBalance)
    setStrHPWAmount(hpwBalance.toString())
  }

  const onKeyUpDepositTokens = e => {
    if (e.keyCode === 13) {
      onDepositTokens()
    }
  }

  return (
    <Modal show={show} onHide={closeModal} title="Synchronize">
      <DepositWrapper>
        <DepositInputGroup>
          <DepositInputInfo>
            <div className="d-flex justify-content-between w-100">
              <h6>HPL</h6>
              <BalanceText>Balance: {formatNumber(hplBalance.toFixed(3))} HPL</BalanceText>
            </div>
          </DepositInputInfo>
          <StyledInputGroup>
            <Input
              ref={ref}
              type="number"
              placeholder="0"
              min="0"
              value={strHPLAmount}
              onChange={onHPLAmountChange}
              onBlur={onHPLBlur}
              onFocus={onFocus}
              borderwidth="0"
              paddinginput="0"
            />
            <Button color="secondary" onClick={onMaHPW}>
              Max
            </Button>
          </StyledInputGroup>
        </DepositInputGroup>
        <div className="d-flex justify-content-center w-100">
          <svg viewBox="0 0 24 24" fill="#fff" width="20px" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z" />
          </svg>
        </div>
        <DepositInputGroup>
          <DepositInputInfo>
            <div className="d-flex justify-content-between w-100">
              <h6>HPW</h6>
              <BalanceText>Balance: {formatNumber(hpwBalance.toFixed(3))} HPW</BalanceText>
            </div>
          </DepositInputInfo>
          <StyledInputGroup>
            <Input
              type="number"
              placeholder="0"
              min="0"
              value={strHPWAmount}
              onChange={onHPWAmountChange}
              onBlur={onHPWBlur}
              onFocus={onFocus}
              onKeyUp={onKeyUpDepositTokens}
              borderwidth="0"
              paddinginput="0"
            />
            <Button color="secondary" onClick={onMaxHPW}>
              Max
            </Button>
          </StyledInputGroup>
        </DepositInputGroup>
        <Button onClick={onDepositTokens} disabled={isLoading} loading={isLoading}>
          Confirm
        </Button>
      </DepositWrapper>
    </Modal>
  )
}

export default DepositTokenModal
