import styled from 'styled-components/macro'
import { UnsupportedChainIdError } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../ToastMessage'
import Modal from '../Modal'
import Button from '../Button'
import { ConnectorNames, connectorsByName } from '../../connectors'
import { connectorLocalStorageKey } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import BtnBg from '../../assets/images/btn-bg.svg'
import ConnectImg from '../../assets/images/connect-title.png'
import MetaMaskLogo from '../../assets/images/metamask.png'
import TrustLogo from '../../assets/images/trust.png'
import Coin98Logo from '../../assets/images/coin98.png'
import WalletConnect from '../../assets/images/walletconnect.svg'

const ImgTitle = styled.img`
  margin-top: -10px;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  button {
    flex-basis: 48%;
    max-width: 48%;
    height: 140px;
    padding: 0;
    margin-bottom: 1.4rem;
    background: url(${BtnBg}) no-repeat center center;
    font-size: 20px;
    border: none;
    transition: all 0.3s ease-in-out;
    &:hover,
    &:active {
      background-color: unset;
    }
    &:hover {
      transform: translateY(-10px);
    }
  }
`
const WalletButton = styled.button`
  width: 100%;
`
const ButtonText = styled.span`
  display: block;
  margin: 7px 0;
  color: #ffcb18;
  font-family: 'Lilita One', cursive;
  font-size: 14px;
  line-height: 26px;
`
const WalletLogo = styled.img`
  width: 40px;
`

interface IConnectModalProps {
  show: boolean
  onHide: () => void
}

function ConnectModal(props: IConnectModalProps): JSX.Element {
  const { show, onHide } = props

  const { activate } = useActiveWeb3React()

  const onConnectWallet = async (connectorID: ConnectorNames) => {
    const connector = connectorsByName[connectorID]

    if (connector) {
      window.localStorage.setItem(connectorLocalStorageKey, connectorID)

      await activate(connector, async (error: Error) => {
        if (error instanceof UnsupportedChainIdError) {
          toast.error(<ToastMessage color="error" bodyText="Wrong network. Please check your chain id." />, {
            toastId: connectorID,
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        } else if (error instanceof NoEthereumProviderError) {
          toast.error(<ToastMessage color="error" bodyText="No provider was found" />, {
            toastId: connectorID,
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        } else if (
          error instanceof UserRejectedRequestErrorInjected ||
          error instanceof UserRejectedRequestErrorWalletConnect
        ) {
          if (connector instanceof WalletConnectConnector) {
            const walletConnector = connector as WalletConnectConnector
            walletConnector.walletConnectProvider = undefined
          }
          toast.error(<ToastMessage color="error" bodyText="Please authorize to access your account" />, {
            toastId: connectorID,
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        } else {
          toast.error(<ToastMessage color="error" bodyText={`${error.name} ${error.message}`} />, {
            toastId: connectorID,
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      })
    } else {
      toast.error(<ToastMessage color="error" bodyText="Can't find connector" />, {
        toastId: connectorID,
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        transition: Zoom,
      })
    }

    onHide()
  }

  return (
    <Modal show={show} hideTitle onHide={onHide} title="Connect Wallet" className="connectModal">
      <div className="text-center">
        <ImgTitle src={ConnectImg} alt="Connect Title" />
      </div>
      <ButtonWrapper>
        <WalletButton onClick={() => onConnectWallet(ConnectorNames.Injected)}>
          <WalletLogo src={MetaMaskLogo} alt="MetaMask" />
          <ButtonText>MetaMask</ButtonText>
        </WalletButton>
        <WalletButton color="secondary" onClick={() => onConnectWallet(ConnectorNames.Injected)}>
          <WalletLogo src={TrustLogo} alt="Trust" />
          <ButtonText>Trust Wallet</ButtonText>
        </WalletButton>
        <WalletButton color="secondary" onClick={() => onConnectWallet(ConnectorNames.Injected)}>
          <WalletLogo src={Coin98Logo} alt="Coin98" />
          <ButtonText>Coin98</ButtonText>
        </WalletButton>
        <WalletButton color="secondary" onClick={() => onConnectWallet(ConnectorNames.WalletConnect)}>
          <WalletLogo src={WalletConnect} alt="WalletConnect" />
          <ButtonText>WalletConnect</ButtonText>
        </WalletButton>
      </ButtonWrapper>
    </Modal>
  )
}

export default ConnectModal
