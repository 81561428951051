import { useMemo, useCallback } from 'react'
import BigNumber from 'bignumber.js'
import { useActiveWeb3React } from './useWeb3'
import { useTokenAllowance } from './useTokenAllowance'
import { useTokenContract } from './useContract'

export enum ApprovalState {
  UNKNOWN = 'UNKNOWN',
  NOT_APPROVED = 'NOT_APPROVED',
  APPROVED = 'APPROVED',
}

export const useApproveCallback = (address?: string, spender?: string): [ApprovalState, () => Promise<any>] => {
  const { account } = useActiveWeb3React()
  const currentAllowance = useTokenAllowance(address, account ?? undefined, spender)
  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!spender) return ApprovalState.UNKNOWN

    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.gt(new BigNumber(0)) ? ApprovalState.APPROVED : ApprovalState.NOT_APPROVED
  }, [currentAllowance, spender])

  const tokenContract = useTokenContract(address)

  const approve = useCallback(
    async (): Promise<void> => {
      if (approvalState !== ApprovalState.NOT_APPROVED) {
        console.error('approve was called unnecessarily')
        return
      }

      if (!address) {
        console.error('no token')
        return
      }

      if (!tokenContract) {
        console.error('tokenContract is null')
        return
      }

      if (!spender) {
        console.error('no spender')
        return
      }

      const infiniteAmount = new BigNumber(2 ** 255 - 1)

      return tokenContract.methods.approve(spender, infiniteAmount.toString(10)).send({ from: account })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [approvalState, address, tokenContract, spender, currentAllowance],
  )

  return [approvalState, approve]
}
