import { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import BigNumber from 'bignumber.js'
import { useActiveWeb3React } from '../../hooks'
import config from '../../config/config.json'
import Main from '../../components/Main'
import ClaimedTable from './ClaimedTable'
import Button from '../../components/Button'
import { HeaderWrapper, GoBackBtn, SectionTitles, BodyWrapper, TableWrapper, ActionsWrapper, EmptyText } from './Styled'
import GuildManagementBg from '../../assets/images/guild-management-bg.png'
import Vector from '../../assets/images/history/vector.svg'

function ClaimedHistory(): JSX.Element {
  const [data, setData] = useState<any>()

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]
  const explorerURL = config.explorerURL[networkId]

  const fetchData = async () => {
    try {
      if (account) {
        const _data = await axios.get(`${apiURL}/masters/history/claimed/${account}`)

        if (_data.data) {
          setData(_data.data)
        }
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [account, networkId])

  const columns = useMemo(
    () => [
      {
        Header: 'Username',
        id: 'username',
        accessor: d => (
          <div style={{ padding: '15px 10px' }}>
            <p style={{ marginBottom: '5px' }}>{d.username}</p>
          </div>
        ),
      },
      {
        Header: 'Claimed Time',
        id: 'claimedtime',
        accessor: d => (
          <div>
            <p style={{ marginBottom: '0' }}>{moment.unix(d.claimTime).format('dddd, MMMM d, YYYY')}</p>
          </div>
        ),
      },
      {
        Header: 'Amount HPL',
        id: 'amountHPL',
        accessor: d => (
          <div>
            <p style={{ marginBottom: '0' }}>{new BigNumber(d.hplAmount).dividedBy(1e18).toFixed(2)} HPL</p>
          </div>
        ),
      },
      {
        Header: 'Amount HPW',
        id: 'amountHPW',
        accessor: d => (
          <div>
            <p style={{ marginBottom: '0' }}>{new BigNumber(d.hpwAmount).dividedBy(1e18).toFixed(2)} HPW</p>
          </div>
        ),
      },
      {
        Header: 'Action',
        id: 'action',
        accessor: d => (
          <>
            <ActionsWrapper>
              <Button color="third" fontSize="medium" icon={true}>
                <a href={`${explorerURL}/address/${d.transactionHash}`} target="_blank">
                  View Scan
                </a>
              </Button>
            </ActionsWrapper>
          </>
        ),
      },
    ],
    [],
  )

  return (
    <Main bgImage={GuildManagementBg}>
      <Container fluid>
        <HeaderWrapper>
          <GoBackBtn>
            <Link to="/manager">
              <img src={Vector} />
            </Link>
          </GoBackBtn>
          <SectionTitles>Claimed History</SectionTitles>
        </HeaderWrapper>
        <Row className="d-flex justify-content-center" style={{ padding: '0 10rem' }}>
          <BodyWrapper>
            {data ? (
              <TableWrapper>
                <ClaimedTable data={data} columns={columns} />
              </TableWrapper>
            ) : (
              <EmptyText>No claimed history yet</EmptyText>
            )}
          </BodyWrapper>
        </Row>
      </Container>
    </Main>
  )
}

export default ClaimedHistory
