import { SelectBoxText, SelectBoxWrapper } from './Styled'
import { FiChevronDown } from 'react-icons/fi'
import { useEffect, useRef, useState } from 'react'

import { useOutsideClick } from '../../hooks'

export type SelectOption = {
  name: string
  value: any
}

interface SelectBoxProps {
  placeholder: string
  options: SelectOption[]
  value: any
  onChange: any
}

function SelectBox(props: SelectBoxProps): JSX.Element {
  const { placeholder, options, value, onChange } = props

  const ref = useRef()
  const [showOptions, setShowOptions] = useState(false)
  const [currentValue, setCurrentValue] = useState(value)

  useOutsideClick(ref, () => setShowOptions(false))

  let _placeholder = placeholder
  const _option = options.find(o => currentValue === o.value)

  if (currentValue != undefined && _option) {
    _placeholder = _option.name
  }

  const onItemClick = (_value: any) => {
    setShowOptions(false)
    setCurrentValue(_value)
    onChange(_value)
  }

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  return (
    // @ts-ignore
    <SelectBoxWrapper className={props.className} ref={ref}>
      <SelectBoxText onClick={() => setShowOptions(!showOptions)}>
        <span>{_placeholder}</span>
        <FiChevronDown />
      </SelectBoxText>
      {showOptions && (
        <ul>
          {options.length ? (
            <>
              {options.map(option => {
                return (
                  <li
                    key={option.value}
                    onClick={() => onItemClick(option.value)}
                    className={value == option.value ? 'active' : ''}
                    data-value={option.value}
                  >
                    {option.name}
                  </li>
                )
              })}
            </>
          ) : (
            <>
              <li></li>
            </>
          )}
        </ul>
      )}
    </SelectBoxWrapper>
  )
}

export default SelectBox
