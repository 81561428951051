import { useState } from 'react'
import styled from 'styled-components/macro'
import ToTopIcon from '../../assets/images/to-top.svg'

const ScrollTopButton = styled.a`
  position: fixed;
  display: block;
  background: url(${ToTopIcon}) no-repeat center center;
  width: 55px;
  height: 55px;
  right: 50px;
  bottom: -100px;
  transition: all 0.5s ease;

  &:hover {
    transform: translateY(-10px);
  }

  &.is-active {
    bottom: 50px;
  }
`

function ScrollTop(): JSX.Element {
  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }

  window.addEventListener('scroll', checkScrollTop)

  const scrollTop = (e: any) => {
    e.preventDefault()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return <ScrollTopButton href="#top" className={`${showScroll && 'is-active'}`} onClick={e => scrollTop(e)} />
}

export default ScrollTop
