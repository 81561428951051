import { useCallback, useRef, useState } from 'react'
import axios, { AxiosError } from 'axios'
import Web3 from 'web3'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import {
  TokenName,
  BalanceText,
  DepositWrapper,
  DepositInputGroup,
  DepositInputInfo,
  Input,
  StyledInputGroup,
  ButtonWrapper,
} from '../SingleAccount/Styled'
import { useActiveWeb3React } from '../../hooks'
import { formatNumber } from '../../utils'
import config from '../../config/config.json'

interface IDepositTokenModalProps {
  type: 'Transfer-out' | 'Transfer-in'
  username: string
  address: string
  hplBalance: any
  hpwBalance: any
  show: boolean
  onHide: () => void
  onRefresh: () => void
}

function DepositTokenModal(props: IDepositTokenModalProps): JSX.Element {
  const { type, username, address, hplBalance, hpwBalance, show, onHide, onRefresh } = props
  const [isLoading, setLoading] = useState(false)
  const [hplAmount, setHPLAmount] = useState(0)
  const [hpwAmount, setHPWAmount] = useState(0)
  const [strHPLAmount, setStrHPLAmount] = useState('0')
  const [strHPWAmount, setStrHPWAmount] = useState('0')

  const { account, chainId, library } = useActiveWeb3React()
  const web3 = new Web3(library)

  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]

  const ref = useRef()

  const closeModal = () => {
    setHPLAmount(0)
    setStrHPLAmount('0')
    setHPWAmount(0)
    setStrHPWAmount('0')
    onHide()
  }

  const onTopUpTokens = async () => {
    try {
      setLoading(true)
      const expiry = (Date.now() / 1000 + 60).toFixed(0)
      const msg = `${account}-${address}-${expiry}`
      const hashedMsg = web3.utils.sha3(msg.toLowerCase())
      if (hashedMsg && account) {
        const signature = await web3.eth.personal.sign(hashedMsg, account, '')
        const lockAccount = await axios.post(`${apiURL}/masters/lockSubAccount`, {
          master: account,
          player: address,
          expiry,
          signature,
        })
        if (lockAccount.status === 200) {
          const response = await axios.post(`${apiURL}/masters/transferToken`, {
            master: account,
            player: address,
            hplAmount,
            hpwAmount,
            expiry,
            signature,
          })
          if (response) {
            toast.success(<ToastMessage color="success" bodyText="Top-Up tokens successful" />, {
              toastId: 'onDepositTokens',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            })
            await onRefresh()
            closeModal()
          }
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const msg = _e.response?.data.errors
        toast.error(<ToastMessage color="error" bodyText={msg ? msg.toUpperCase() : 'Error'} />, {
          toastId: 'onDepositTokens',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onWithDrawTokens = async () => {
    try {
      setLoading(true)
      const expiry = (Date.now() / 1000 + 60).toFixed(0)
      const msg = `${account}-${address}-${expiry}`
      const hashedMsg = web3.utils.sha3(msg.toLowerCase())
      if (hashedMsg && account) {
        const signature = await web3.eth.personal.sign(hashedMsg, account, '')
        const lockAccount = await axios.post(`${apiURL}/masters/lockSubAccount`, {
          master: account,
          player: address,
          expiry,
          signature,
        })
        if (lockAccount.status === 200) {
          const response = await axios.post(`${apiURL}/masters/withdrawToken`, {
            master: account,
            player: address,
            hplAmount,
            hpwAmount,
            expiry,
            signature,
          })
          if (response) {
            toast.success(<ToastMessage color="success" bodyText="Withdraw tokens successful" />, {
              toastId: 'onDepositTokens',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            })
            await onRefresh()
            closeModal()
          }
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const msg = _e.response?.data.errors
        toast.error(<ToastMessage color="error" bodyText={msg ? msg.toUpperCase() : ''} />, {
          toastId: 'onDepositTokens',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onHPLAmountChange = (e: any) => {
    const { value } = e.currentTarget
    setStrHPLAmount(value)
    setHPLAmount(parseInt(value, 10) || 0)
  }

  const onHPWAmountChange = (e: any) => {
    const { value } = e.currentTarget
    setStrHPWAmount(value)
    setHPWAmount(parseInt(value, 10) || 0)
  }

  const onHPLBlur = useCallback(() => {
    setStrHPLAmount(hplAmount.toString())
  }, [hplAmount])

  const onHPWBlur = useCallback(() => {
    setStrHPWAmount(hpwAmount.toString())
  }, [hpwAmount])

  const onFocus = (e: any) => {
    e.target.select()
  }

  const onMaxHPL = () => {
    setHPLAmount(hplBalance)
    setStrHPLAmount(hplBalance.toString())
  }

  const onMaxHPW = () => {
    setHPWAmount(hpwBalance)
    setStrHPWAmount(hpwBalance.toString())
  }

  const onKeyUpDepositTokens = e => {
    if (e.keyCode === 13) {
      onTopUpTokens()
    }
  }

  return (
    <Modal show={show} onHide={closeModal} title={type === 'Transfer-in' ? 'Transfer-in' : 'Transfer-out'}>
      <DepositWrapper className="mt-5">
        <DepositInputGroup>
          <DepositInputInfo>
            <div className="d-flex justify-content-between w-100">
              <TokenName>HPL</TokenName>
              <BalanceText>Available: {formatNumber(hplBalance.toFixed(3))} HPL</BalanceText>
            </div>
          </DepositInputInfo>
          <StyledInputGroup>
            <Input
              ref={ref}
              type="number"
              placeholder="0"
              min="0"
              value={strHPLAmount}
              onChange={onHPLAmountChange}
              onBlur={onHPLBlur}
              onFocus={onFocus}
              borderwidth="0"
              paddinginput="0"
            />
            <Button color="secondary" onClick={onMaxHPL}>
              Max
            </Button>
          </StyledInputGroup>
        </DepositInputGroup>
        <DepositInputGroup>
          <DepositInputInfo>
            <div className="d-flex justify-content-between w-100">
              <TokenName>HPW</TokenName>
              <BalanceText>Available: {formatNumber(hpwBalance.toFixed(3))} HPW</BalanceText>
            </div>
          </DepositInputInfo>
          <StyledInputGroup>
            <Input
              type="number"
              placeholder="0"
              min="0"
              value={strHPWAmount}
              onChange={onHPWAmountChange}
              onBlur={onHPWBlur}
              onFocus={onFocus}
              onKeyUp={onKeyUpDepositTokens}
              borderwidth="0"
              paddinginput="0"
            />
            <Button color="secondary" onClick={onMaxHPW}>
              Max
            </Button>
          </StyledInputGroup>
        </DepositInputGroup>
        <ButtonWrapper style={{ padding: '0' }}>
          {type === 'Transfer-in' && (
            <Button onClick={onTopUpTokens} disabled={isLoading} loading={isLoading}>
              Transfer-in
            </Button>
          )}
          {type === 'Transfer-out' && (
            <Button onClick={onWithDrawTokens} disabled={isLoading} loading={isLoading}>
              Transfer-out
            </Button>
          )}
        </ButtonWrapper>
      </DepositWrapper>
    </Modal>
  )
}

export default DepositTokenModal
