import styled from 'styled-components/macro'

export const EmptyPageWrapper = styled.div`
  padding-top: 15vh;
  padding-bottom: 15vh;
  text-align: center;
`

interface ILoadingProps {
  children: JSX.Element
}

function EmptyPage(props: ILoadingProps): JSX.Element {
  const { children } = props

  return <EmptyPageWrapper>{children}</EmptyPageWrapper>
}

export default EmptyPage
