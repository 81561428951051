import { useEffect, useState } from 'react'
import { Container, Offcanvas } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import LogoPNG from '../../assets/images/logo.png'
import AccountButton from '../AccountButton'
import config from '../../config/config.json'
import { formatNumber } from '../../utils'
import { useTokenBalance, useActiveWeb3React } from '../../hooks'
import {
  HeaderMain,
  HeaderWrapper,
  HeaderTopLogin,
  LogoWrapper,
  LeftMenu,
  RightMenu,
  Logo,
  MenuLink,
  MenuA,
  MobileMenuA,
  BalanceInner,
  MobileMenu,
  MobileMenuLink,
  MenuToggle,
  BalanceText,
  BalanceNumber,
  BalancesWrapper,
} from './Styled'
import { FiMenu } from 'react-icons/fi'

function Header(): JSX.Element {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const location = useLocation()
  const { pathname } = location

  const [hplBalance, setHPLBalance] = useState(0)
  const [hpwBalance, setHPWBalance] = useState(0)

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const hplBalanceCallback = useTokenBalance(config.contracts[networkId].HPL, account)
  const hpwBalanceCallback = useTokenBalance(config.contracts[networkId].HPW, account)

  const fetchData = async () => {
    const _hplBalance = await hplBalanceCallback()
    const _hpwBalance = await hpwBalanceCallback()
    setHPLBalance(_hplBalance)
    setHPWBalance(_hpwBalance)
  }

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true)
  }

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false)
  }

  useEffect(() => {
    fetchData()

    const interval = setInterval(() => {
      fetchData()
    }, 1000 * 10)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hplBalanceCallback, hpwBalanceCallback])

  return (
    <HeaderMain>
      <Container>
        <HeaderWrapper>
          <MenuToggle onClick={handleMobileMenuOpen}>
            <FiMenu size={24} />
          </MenuToggle>
          <LeftMenu>
            <MenuA href="https://app.happyland.finance/marketplace">Marketplace</MenuA>
            <MenuLink to="/manager" className={`${pathname === '/manager' && 'active'}`}>
              Account Manager
            </MenuLink>
            <MenuLink to="/synchronize" className={`${pathname === '/synchronize' && 'active'}`}>
              Synchronize
            </MenuLink>
          </LeftMenu>
          <LogoWrapper>
            <Link to="/">
              <Logo src={LogoPNG} alt="Happy Land" />
            </Link>
          </LogoWrapper>
          <RightMenu>
            <MenuLink to="/inventory" className={`${pathname === '/inventory' && 'active'}`}>
              Inventory
            </MenuLink>
            <MenuA href="https://docs.happyland.finance/master" target="_blank" rel="nofollow">
              Docs
            </MenuA>
            <HeaderTopLogin>
              <AccountButton />
            </HeaderTopLogin>
          </RightMenu>
          {account ? (
            <BalancesWrapper className="d-flex justify-content-between align-items-center">
              <BalanceInner>
                <BalanceText>HPW Balance</BalanceText>
                <BalanceNumber>{formatNumber(hpwBalance.toFixed(3))}</BalanceNumber>
              </BalanceInner>
              <BalanceInner>
                <BalanceText>HPL Balance</BalanceText>
                <BalanceNumber>{formatNumber(hplBalance.toFixed(3))}</BalanceNumber>
              </BalanceInner>
            </BalancesWrapper>
          ) : null}
        </HeaderWrapper>
        <Offcanvas show={mobileMenuOpen} onHide={handleMobileMenuClose}>
          <Offcanvas.Header closeButton closeVariant="white" />
          <Offcanvas.Body>
            <MobileMenu>
              <li>
                <MenuA href="https://app.happyland.finance/marketplace">Marketplace</MenuA>
              </li>
              <li>
                <MobileMenuLink
                  to="/manager"
                  onClick={handleMobileMenuClose}
                  className={`${pathname === '/manager' && 'active'}`}
                >
                  Account Manager
                </MobileMenuLink>
              </li>
              <li>
                <MobileMenuLink
                  to="/synchronize"
                  onClick={handleMobileMenuClose}
                  className={`${pathname === '/synchronize' && 'active'}`}
                >
                  Synchronize
                </MobileMenuLink>
              </li>
              <li>
                <MobileMenuLink
                  to="/inventory"
                  onClick={handleMobileMenuClose}
                  className={`${pathname === '/inventory' && 'active'}`}
                >
                  Inventory
                </MobileMenuLink>
              </li>
              <li>
                <MobileMenuA
                  href="https://docs.happyland.finance/master"
                  onClick={handleMobileMenuClose}
                  target="_blank"
                  rel="nofollow"
                >
                  Docs
                </MobileMenuA>
              </li>
            </MobileMenu>
          </Offcanvas.Body>
        </Offcanvas>
      </Container>
    </HeaderMain>
  )
}

export default Header
