import { Tabs } from 'react-bootstrap'
import styled from 'styled-components/macro'

export const StyledTabs = styled(Tabs)`
  display: flex;
  background: rgba(233, 195, 146, 0.3);
  border: 2px solid #731c00;
  height: 71px;
  width: fit-content;
  align-items: center;
  margin: 0 auto 3rem;
  padding-right: 0;
  border-radius: 20px;
  padding: 5px;

  .nav-item {
    border-radius: 0;
    font-family: 'Lilita One', cursive;
    line-height: 1;
    font-size: 24px;
    height: 51px;
  }

  .nav-link {
    padding: 10px 24px;
    color: #731c00;
    height: 51px;
    border-radius: 14px;

    &.active {
      background-color: #731c00;
      color: #fff;
    }
  }
`

interface ITabProps {
  defaultActiveKey: string
  children?: string | JSX.Element | JSX.Element[]
}

function StyledTab(props: ITabProps): JSX.Element {
  const { children, defaultActiveKey } = props
  return (
    <StyledTabs defaultActiveKey={defaultActiveKey} transition={false}>
      {children}
    </StyledTabs>
  )
}

export default StyledTab
