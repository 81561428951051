import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import NFT from '../../../types/NFT'
import { NFTItemWrapper, SmallNFTID } from '../Styled'

interface INFTListItem {
  nft: NFT
  selectAll: boolean
  onRefresh: () => void
  onItemSelectedCallback: (tokenId: number, selected: boolean) => void
}

function NFTListItem(props: INFTListItem): JSX.Element {
  const { nft, selectAll, onItemSelectedCallback } = props
  const [isSelected, setSelected] = useState(false)

  // @ts-ignore

  const onSelectItem = () => {
    setSelected(!isSelected)
    onItemSelectedCallback(nft.tokenId, !isSelected)
  }

  useEffect(() => {
    setSelected(selectAll)
  }, [selectAll])

  return (
    <NFTItemWrapper key={nft.tokenId} className={isSelected || selectAll ? 'is-selected' : ''}>
      <div className="form-check">
        <Form.Check checked={isSelected} type="checkbox" onChange={onSelectItem} />
      </div>
      <h6>
        <SmallNFTID>#{nft.tokenId} </SmallNFTID>
        {nft.land}
      </h6>
    </NFTItemWrapper>
  )
}

export default NFTListItem
